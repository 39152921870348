import React from "react"
import type { TTextFieldProps } from "../types"
import { classNames } from "@local/utils"
import styles from "../styles.module.css"

export const TextField = React.forwardRef<HTMLInputElement, TTextFieldProps>(
  (
    {
      autocomplete,
      className,
      disabled,
      errors,
      fullWidth,
      label,
      marginTop,
      name,
      onBlur,
      type = "text",
      ...rest
    }: TTextFieldProps,
    ref
  ) => (
    <div
      className={classNames(
        marginTop ? "mt-4" : "",
        fullWidth ? "sm:col-span-2" : "",
        className ? className : ""
      )}
    >
      <div className="m-0!">
        <input
          onWheel={(e) => e.currentTarget.blur()}
          onBlur={onBlur}
          type={type}
          id={name}
          autoComplete={autocomplete}
          className={classNames(
            "block w-full border-gray-200 shadow-sm focus:border-zinc-500 focus:ring-zinc-500 sm:text-sm placeholder-gray-300 py-3",
            disabled ? "bg-gray-200" : "",
            type === "number" ? styles.PhoneNumberInput : ""
          )}
          disabled={disabled}
          placeholder={label}
          ref={ref}
          {...rest}
          aria-required
          aria-label={name}
        />
      </div>
      {errors && errors[name] && errors[name] && (
        <p className="text-sm text-red-500">{errors[name]?.message}</p>
      )}
    </div>
  )
)

TextField.displayName = "TextField"

export default TextField
