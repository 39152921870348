// import useSWR from "swr"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  SlotPrices,
  SlotPricesTable,
  UpsellOutput,
  getUpsell,
} from "./fetchers"
import { useI10n } from "@local/i10n"
import { CartItem, Currency } from "@local/cart/src/types"
import type { Order } from "@local/order/src/useOrder/types"
import { trackAction } from "@local/tracking/src/trackActions"

export type UpsellStage = null | 1 | 2 | 3
type UpsellType = "single" | "multi"

interface Props {
  items: CartItem[]
  orders: Order[]
  channel: string
  prefetchedData?: UpsellOutput
}

/**
 * The use upsell hook is a massive controller for client side data
 * that we use to figure out what to show for the user, and, it also
 * controls what stage the user is in their upsell journey of losing
 * a thousand pounds.
 * @returns `data` - response from SWR with all upsell workflow
 * @returns `stage` - which stage is the current upsell, it's a `UpsellType`
 * @returns `setStage` allow to change the current upsellFlow
 * @returns `slotData` - returns the data for that specific slot
 * @returns `slotPrice` - an object with all info for displaying that slot price
 * @returns `orderPaymentData` - an object which payment method was used so it can trigger the right upsell button
 */
export const useUpsell = ({
  items = [],
  orders = [],
  channel,
  prefetchedData,
}: Props) => {
  const { currencyCode } = useI10n()

  // Figure out the most expensive product from cart
  const { mostExpensiveProductId, upsellTypeId } = useMemo(() => {
    let mostExpensiveProduct = null
    let upsellTypeId: UpsellType = "single"

    if (items.length > 0) {
      const filteredAddons = items.filter(
        (item) => !item.hasOwnProperty("isAddon") || item.isAddon === false
      )

      const sortedProducts = filteredAddons.sort((a, b) => {
        if (a.price === b.price) {
          return -1
        }
        return a.price! - b.price!
      })
      mostExpensiveProduct = sortedProducts[sortedProducts.length - 1]

      if (sortedProducts.length > 1 || sortedProducts[0].quantity > 1) {
        upsellTypeId = "multi"
      }
    } else if (orders.length > 0) {
      const orderItems = orders[0].items
      const sortedProducts = orderItems.sort((a, b) => a?.price! - b?.price!)
      mostExpensiveProduct = sortedProducts[sortedProducts.length - 1]

      if (sortedProducts.length > 1 || sortedProducts[0].quantity > 1) {
        upsellTypeId = "multi"
      }
    }

    const mostExpensiveProductId = mostExpensiveProduct?.productId ?? null

    return {
      mostExpensiveProductId,
      upsellTypeId,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  // TODO: useSWR stopped working, so well be using a very hacky way of getting the data below for now.
  const [data, setData] = useState<UpsellOutput>()
  const [stage, setStage] = useState<UpsellStage>(null)

  // weird, unortodox and all but it's currently avoiding nonstop rerendering
  const fetchData = useCallback(async () => {
    const res = await getUpsell({
      channel,
      productId: mostExpensiveProductId,
      type: upsellTypeId,
    })

    if (res) {
      return res
    }
  }, [channel, mostExpensiveProductId, upsellTypeId])

  useEffect(() => {
    if (!prefetchedData) {
      fetchData().then((res) => setData(res))
    } else {
      setData(prefetchedData)
    }
  }, [prefetchedData, fetchData])

  // Control what is the current upsell stage
  const slotData = useMemo(() => {
    return stage ? data?.slots[stage - 1] : null
  }, [data, stage])

  const slotPrices: SlotPricesTable = useMemo(() => {
    const discountType =
      slotData?.discountType === "discount" ? "percent" : "amount"

    const prices: any = new Map()
    const discount = slotData?.prices.get(currencyCode as Currency)
      ?.discount as number

    slotData?.products.map((product) => {
      const origPrice =
        ((currencyCode && currencyCode !== "USD"
          ? product.origPrices?.find((e) => e.currency === currencyCode)?.price
          : product.origPrice) as number) * 100

      const price = origPrice - (origPrice * discount) / 100

      prices.set(product.id, {
        price: Math.ceil(price),
        origPrice: Math.ceil(origPrice),
      } as SlotPrices)
    })

    if (slotData) {
      trackAction("upsell_viewed", {
        // url: window.location.href,
        currency: currencyCode,
        discount: discount / 100, // It comes as integer and need to be float.
        image_url: slotData.products?.[0].images?.[0]?.image?.url,
        price: slotData.products?.[0].origPrice * 100, // It comes as float and need to be integer.
        item_id: slotData.products?.[0]?.id,
        item_name: slotData.products[0]?.name,
        quantity: 1,
        upsell_id: slotData.upsellId,
        upsell_slot: stage,
        upsell_version: slotData.version,
      })
    }

    return {
      discountType,
      discount,
      prices,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, slotData, stage])

  const orderPaymentData = useMemo(() => {
    const order = orders?.[orders.length - 1]
    const paymentMethod = order?.method ?? "invalid"
    const isCard = paymentMethod.toUpperCase().indexOf("CARD") > -1
    const isPayPal = paymentMethod.toUpperCase().indexOf("PAYPAL") > -1
    const isStripe = paymentMethod.toUpperCase().indexOf("STRIPE") > -1
    const isUpsellEligible = isCard || isPayPal || isStripe

    return {
      paymentMethod,
      isCard,
      isPayPal,
      isStripe,
      isUpsellEligible,
    }
  }, [orders])

  const isLoading = typeof slotData === "undefined" && !data?.isError

  return {
    data,
    error: data?.isError,
    isLoading,
    orderPaymentData,
    setStage,
    slotData,
    slotPrices,
    stage,
  }
}
