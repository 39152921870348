import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import { OrderState } from "./types"
// import { isBrowser } from "@local/utils/src/isBrowser"

export const useOrder = create<OrderState>()(
  persist(
    (setState, getState) => ({
      address: null,
      addOrder: (order) => {
        const prevState = getState().orders
        // Need to check if this order already exists in the storage
        const match = prevState.findIndex(
          (item) => item.id === order.id || item.uid === order.uid
        )
        if (match === -1) {
          const newState = [...prevState, order]

          setState({
            orders: newState,
          })
        }

        return true
      },
      setAddress: (address) => {
        setState({
          address,
        })

        return address
      },
      orders: [],
    }),
    {
      name: "order",
      storage: createJSONStorage(() => localStorage),
      version: 4,
    }
  )
)

/**
 * This here happens to refresh the order history every 7 days
 * So users might not get the whole thing FOREVER
 */

/*
// TODO: Future implementation for cleaning up
if (isBrowser()) {
  const firstOrder = useOrder.getState().orders?.[0]
  const now = new Date().getTime()

  const diff = now

  console.log(0, firstOrder.createdAt)

  if (firstOrder && firstOrder.createdAt) {
    const createdAt = firstOrder?.createdAt
    if (createdAt) {
      const threeDaysAgo = new Date().getTime() - 3 * 24 * 60 * 60 * 1000
      const createdAtTime = new Date(createdAt).getTime()
      const isOlderThanThreeDays = createdAtTime < threeDaysAgo
      // Do something about it
    }
  }
}
*/
