"use client"
import Link from "next/link"

export const PlaceholderClient = ({
  finished,
  showLink,
}: {
  finished?: boolean
  showLink?: boolean
}) => {
  return (
    <div className="flex flex-col justify-center pb-8 rounded-md h-96">
      <p className="text-xl font-bold text-center  font-slate-900">
        {finished
          ? "Don't Close This Window! Loading..."
          : "Wait! your order is not yet complete..."}
      </p>
      {showLink ? (
        <Link href="/order/confirm" className="text-indigo-400 underline">
          Click here if you're not automatically redirected...
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}
