import { useEffect, useState, useRef } from "react"
import { Controller } from "react-hook-form"
import { getMatchingEmailProviders } from "../helpers/emailProviders"
import type { TEmailCompleteProps } from "../types"
import { emailProviders } from "../helpers/emailProviders"
import emailSpellChecker from "@zootools/email-spell-checker"

export const EmailComplete = ({
  label,
  email,
  errors,
  disabled,
  control,
}: TEmailCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const mailProviders = getMatchingEmailProviders(email)
  const [suggest, setSuggest] = useState<string>("")

  useEffect(() => {
    if (inputRef.current) {
      // Enable browser autocomplete
      inputRef.current.setAttribute("autocomplete", "email")
      inputRef.current.removeAttribute("aria-autocomplete")
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      if (disabled) {
        inputRef.current.setAttribute("disabled", "disabled")
      } else {
        inputRef.current.removeAttribute("disabled")
      }
    }
  }, [disabled])

  useEffect(() => {
    const suggestion = emailSpellChecker.run({
      email: email || "",
      domains: [...emailSpellChecker.POPULAR_DOMAINS, ...emailProviders],
    })

    if (suggestion) {
      setSuggest(suggestion.full)
    } else {
      setSuggest("")
    }
  }, [email])

  return (
    <div className="mt-4 sm:col-span-2">
      <label
        htmlFor={"email"}
        className="block text-sm font-medium text-zinc-700"
      >
        {label}
      </label>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <input
                type="text"
                className="w-full py-3 pl-3 pr-10 bg-white border-0 rounded-sm shadow-sm text-zinc-700 ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-zinc-500 sm:text-sm"
                onChange={(event) => onChange(event.target.value)}
                value={value}
                autoCapitalize="none"
                ref={inputRef}
                name="email"
                id="email"
                list="email-suggestions"
                aria-required
                aria-label="Email Address"
              />
              {mailProviders.length > 0 &&
                value.includes("@") &&
                value.split("@")[1].length >= 1 && (
                  <datalist id="email-suggestions">
                    {mailProviders.map((email) => (
                      <option key={email}>{email}</option>
                    ))}
                  </datalist>
                )}
              {suggest && (
                <button
                  type="button"
                  className="text-sm text-red-400 mt-[6px] ml-2.5"
                  onClick={() => onChange(suggest)}
                >
                  Did you mean: <span className="font-medium">{suggest}</span> ?
                </button>
              )}
            </>
          )
        }}
      />
      {errors && errors["email"] && (
        <p className="text-sm text-red-500">{errors["email"]?.message}</p>
      )}
    </div>
  )
}

export default EmailComplete
