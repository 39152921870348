export const LoadingSpinner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    display="block"
    preserveAspectRatio="xMidYMid"
    style={{ margin: "0 5px" }}
    viewBox="0 0 100 100"
    aria-hidden="true"
  >
    <circle cx="82" cy="50" r="10" fill="#ededed">
      <animate
        attributeName="r"
        begin="-0.4317789291882556s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="6;6;10;6;6"
      />
      <animate
        attributeName="fill"
        begin="-0.4317789291882556s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="#ededed;#ededed;#000000;#ededed;#ededed"
      />
    </circle>
    <circle cx="66" cy="77.713" r="10" fill="#ededed">
      <animate
        attributeName="r"
        begin="-0.3454231433506045s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="6;6;10;6;6"
      />
      <animate
        attributeName="fill"
        begin="-0.3454231433506045s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="#ededed;#ededed;#000000;#ededed;#ededed"
      />
    </circle>
    <circle cx="34" cy="77.713" r="10" fill="#ededed">
      <animate
        attributeName="r"
        begin="-0.25906735751295334s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="6;6;10;6;6"
      />
      <animate
        attributeName="fill"
        begin="-0.25906735751295334s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="#ededed;#ededed;#000000;#ededed;#ededed"
      />
    </circle>
    <circle cx="18" cy="50" r="10" fill="#ededed">
      <animate
        attributeName="r"
        begin="-0.17271157167530224s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="6;6;10;6;6"
      />
      <animate
        attributeName="fill"
        begin="-0.17271157167530224s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="#ededed;#ededed;#000000;#ededed;#ededed"
      />
    </circle>
    <circle cx="34" cy="22.287" r="10" fill="#ededed">
      <animate
        attributeName="r"
        begin="-0.08635578583765112s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="6;6;10;6;6"
      />
      <animate
        attributeName="fill"
        begin="-0.08635578583765112s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="#ededed;#ededed;#000000;#ededed;#ededed"
      />
    </circle>
    <circle cx="66" cy="22.287" r="10" fill="#ededed">
      <animate
        attributeName="r"
        begin="0s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="6;6;10;6;6"
      />
      <animate
        attributeName="fill"
        begin="0s"
        dur="0.5181347150259068s"
        repeatCount="indefinite"
        values="#ededed;#ededed;#000000;#ededed;#ededed"
      />
    </circle>
  </svg>
)
