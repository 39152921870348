export interface StepProps {
  isActive: boolean
  number: number
  name: string
}

export const CheckoutStep = ({ isActive, number, name }: StepProps) => {
  const activeClasses =
    "flex items-center justify-center w-5 h-5 rounded-full bg-zinc-900 text-white mr-2 text-xs"
  const inactiveClasses =
    "flex items-center justify-center w-5 h-5 rounded-full bg-gray-300 text-black font-medium mr-2 text-xs"

  return (
    <div
      className={`flex items-center text-sm ${
        isActive ? "text-zinc-900 font-semibold" : "text-gray-500"
      }`}
    >
      <div className={isActive ? activeClasses : inactiveClasses}>{number}</div>
      <p className="">{name}</p>
    </div>
  )
}

export const Step = ({ phase }: { phase: string }) => {
  return (
    <div className="flex my-4 space-x-4 justify-between">
      <CheckoutStep isActive={true} number={1} name="Contact" />
      <CheckoutStep isActive={true} number={2} name="Shipping" />
      <CheckoutStep
        isActive={phase === "payment" ? true : false}
        number={3}
        name="Payment"
      />
    </div>
  )
}

export default Step
