import type { Payload } from "@local/payload-client/src/types"
import { StylePickerSingleServer } from "./StylePickerSingle"
import { OptionsSelectorSingleItemState } from "../types"
import { SlotPricesTable } from "../../fetchers"

interface Props {
  data: Map<Payload.Product["id"], Payload.Product>
  selectedProductId: Payload.Product["id"]
  handleChange: (productId: Payload.Product["id"], key?: number) => void
  slotPrices: SlotPricesTable
}

export const StylePickerServer = ({
  data,
  selectedProductId,
  handleChange,
  slotPrices,
}: Props) => (
  <>
    <b>Select Your Style:</b>
    <ul className="grid justify-center grid-cols-3 gap-2 my-3">
      {Array.from(data.values()).map((product, i) => (
        <StylePickerSingleServer
          product={product}
          state={
            product.id === selectedProductId
              ? OptionsSelectorSingleItemState.selected
              : null
          }
          key={product.id}
          changeProduct={handleChange}
          optionIndex={i}
          price={slotPrices.prices.get(product.id)?.price as number}
        />
      ))}
    </ul>
  </>
)
