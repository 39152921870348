"use client"

import { Dispatch, SetStateAction, useState } from "react"
import { KlarnaCheckoutSetup } from "./KlarnaCheckoutSetup"
import { Klarna } from "./types"

type Props = Klarna.KlarnaCheckoutButtonProps & {
  slug?: string | null
  setExpressClicked?: (expressClicked: boolean) => void
}

export function KlarnaCheckoutButton({
  hideLoading,
  idSufix,
  onSuccess,
  slug,
  setExpressClicked,
  upsell,
}: Props) {
  const [loaded, setLoaded] = useState(!!upsell ? true : false)
  const [orderLoading, setOrderLoading] = useState(false)

  return (
    <div
      className="w-full"
      id={`klarna-express-checkout-wrapper${!!idSufix ? `-${idSufix}` : ""}`}
      onClick={() => {
        if (loaded) {
          setExpressClicked && setExpressClicked(true)
        }
      }}
    >
      <KlarnaCheckoutSetup
        idSufix={idSufix}
        onLoaded={() => setLoaded(true)}
        onOrderLoading={(value) => setOrderLoading(value)}
        onSuccess={onSuccess}
        slug={slug}
        upsell={upsell}
      />

      {!loaded && (
        <div className="items-center justify-center hidden h-14">
          Loading Klarna Express Checkout...
        </div>
      )}

      {orderLoading && !hideLoading && (
        <div className="fixed top-0 left-0 w-screen h-screen z-[200] bg-black/75 flex items-center justify-center">
          <p className="text-2xl font-semibold text-white">
            Finalizing the purchase...
          </p>
        </div>
      )}
    </div>
  )
}
