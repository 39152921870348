"use client"

import { useI10n } from "@local/i10n"
import {
  PayPalScriptProvider,
  ReactPayPalScriptOptions,
} from "@paypal/react-paypal-js"

export const PaypalProviderWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { currencyCode } = useI10n()

  const options = {
    // TODO: CLIENT_ID not working for MESSAGES. Seems we need to enable something on PayPal.
    // TODO: `X-Frame-Options` header is coming `DISALLOWED` on the response header.
    // TODO: works with "test" `clientId`
    clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
    // clientId: "test",
    components: ["buttons", "messages"],
    currency: currencyCode,
    disableFunding: [
      // "credit",
      "card",
      "bancontact",
      "blik",
      "eps",
      "ideal",
      "giropay",
      "mercadopago",
      "mybank",
    ],
    enableFunding: ["paylater"], // add "venmo" when possible
    intent: "capture",
  } as ReactPayPalScriptOptions

  return (
    <>
      {process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID && currencyCode ? (
        <PayPalScriptProvider deferLoading={true} options={options}>
          {children}
        </PayPalScriptProvider>
      ) : (
        children
      )}
    </>
  )
}
