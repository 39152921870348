"use client"

import { Payload } from "@local/payload-client/src/types"
import { Fragment, useId, useMemo, useState } from "react"
import Image from "next/image"
import { classNames } from "@local/utils"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
import { useI10n } from "@local/i10n"

interface SizeChartProps {
  data: Array<Payload.ProductSizeChart>
  product?: string
}

export const SizeChartsClient = ({ product, data }: SizeChartProps) => {
  const { country } = useI10n()
  const id = useId()

  const [open, setOpen] = useState(false)

  const charts = useMemo(() => {
    const results: Array<Payload.ProductRegionalSizeChart & { name: string }> =
      []
    data.forEach((item) => {
      item.regionalSizeCharts?.forEach((subitem) => {
        if (subitem.region?.includes(country)) {
          results.push({
            ...subitem,
            name: item.name,
          })
        }
      })
    })
    return results
  }, [country, data])
  return (
    <details>
      <summary
        className="text-sm font-medium text-red-700"
        onClick={() => setOpen(!open)}
      >
        {open ? "Click to Hide Sizing Chart" : "See Sizing Chart"}
      </summary>

      {charts.map((item, i) => (
        <Fragment key={`size-chart-${id}-${i}`}>
          <header className="mb-2 text-xl font-bold">
            <h3 className="mt-3 mb-4 text-3xl text-center">
              {product ? `${product} ${item.name}` : item.name}
            </h3>
          </header>
          {item.explainer && (
            <p
              className="my-2 text-left"
              dangerouslySetInnerHTML={{
                __html: item.explainer.replaceAll("\n", "<br />"),
              }}
            />
          )}
          {item.images &&
            item.images.map(({ image }) => (
              <Image
                key={image?.id}
                src={image?.url as string}
                alt={item.name || "size chart"}
                width={image?.width}
                height={image?.height}
                className="w-auto mx-auto"
                style={{ maxHeight: "500px" }}
                loader={
                  process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined
                }
              />
            ))}

          <div className="px-4 overflow-x-scroll">
            <table className="w-full text-center border-separate text-xxs border-spacing-2 sm:text-xs">
              <thead>
                <tr className="font-thin text-white uppercase bg-black">
                  {item.values?.headers?.["0"]
                    .split(",")
                    .map((header: any, i: number) => (
                      <th
                        key={`sizechart-${id}-header-${i}`}
                        className="px-1 py-2 font-bold sm:px-4"
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="w-full text-center">
                {item.values.values.map((value, i) => (
                  <tr
                    key={`sizechart-${id}-value-${i}`}
                    className={classNames(
                      i % 2 === 0 ? "bg-gray-100" : "bg-gray-50"
                    )}
                  >
                    {value["0"].split(",").map((column, j) => (
                      <td
                        key={`sizechart-${id}-value-${i}-col-${j}`}
                        className="px-1 py-2 font-bold sm:px-4"
                      >
                        {column}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={100}
                    className="text-white uppercase text-xxs bg-sky-500 sm:text-base"
                  >
                    Shop with confidence - Free exchanges
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Fragment>
      ))}
    </details>
  )
}
