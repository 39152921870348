"use client"
import { clearRequestInterval, requestInterval } from "@local/utils"
import { useEffect, useState } from "react"

interface Props {
  minutes?: number
}

export const Counter = ({ minutes = 10 }: Props) => {
  const now = new Date()

  const [deadline, _setDeadline] = useState(
    new Date().setMinutes(now.getMinutes() + minutes)
  )

  const [seconds, setSeconds] = useState(deadline - Date.now() > 0 ? 600 : -1)

  // Hook to update the timer on the component
  useEffect(() => {
    const interval = requestInterval(() => {
      const time = deadline - Date.now()
      if (time >= -1 && seconds !== -1) {
        setSeconds(Math.floor(time / 1000))
      }
    }, 1000)

    return () => clearRequestInterval(interval)
  }, [deadline, seconds])

  return <>Only {new Date(seconds * 1000).toISOString().slice(14, 19)} left</>
}
