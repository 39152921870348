import Image from "next/image"
import { ImageAmex, ImageMastercard, ImageVisa, ImageSectigo } from "./images"

interface Props {
  fillHeight?: boolean
  limit?: number
}

type CardItem = {
  src: typeof import("*.svg") | string
  alt: string
  width?: number
  height?: number
}

type CardProps = CardItem & Props

export const Icon = ({ src, alt, fillHeight, width, height }: CardProps) => (
  <div
    className="relative"
    style={fillHeight ? { height: "100%", width: "auto" } : {}}
  >
    <Image
      src={src}
      alt={alt}
      style={fillHeight ? { height: "100%", width: "auto" } : {}}
      width={width}
      height={height}
    />
  </div>
)

export const TrustIcons = ({ limit, fillHeight }: Props) => {
  const icons: CardItem[] = [
    { src: ImageVisa, alt: "Visa" },
    { src: ImageMastercard, alt: "Mastercard" },
    { src: ImageAmex, alt: "American Express" },
    // { src: ImageNorton, alt: "Norton Secure" },
    { src: ImageSectigo, alt: "Sectigo" },
  ]

  return (
    <div
      className="my-4 px-2 bf grid items-center justify-between gap-x-4 align-middle grid-cols-4"
      style={fillHeight ? { height: "100%" } : {}}
    >
      {icons.slice(0, limit ?? icons.length).map((item) => (
        <Icon
          key={item.alt}
          src={item.src}
          alt={item.alt}
          fillHeight={fillHeight}
          width={item.width}
          height={item.height}
        />
      ))}
    </div>
  )
}
