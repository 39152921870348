"use client"

import { Suspense, useEffect, useState } from "react"
import { UpsellStage, useUpsell } from "./useUpsell"
import { SlotOutput, UpsellOutput } from "./fetchers"
import { ErrorBoundary } from "@sentry/nextjs"
import { UpsellComponent } from "./Components"
import { Order } from "@local/order/src/useOrder/types"
import { PlaceholderClient } from "./Placeholder"
import Cookies from "js-cookie"
import { CartItem } from "@local/cart/src/types"
import * as Sentry from "@sentry/nextjs"

interface Props {
  setShowOrder?: () => void
  isValidMethod: boolean
  orders: Order[]
  modalRef?: React.MutableRefObject<HTMLDivElement | null>
  items: CartItem[]
  channel: string
  prefetchedData?: UpsellOutput
}

export const UpsellClient = ({
  setShowOrder,
  isValidMethod,
  items,
  orders,
  modalRef,
  channel,
  prefetchedData,
}: Props) => {
  const {
    data,
    isLoading,
    setStage: setHookStage,
    slotData,
    slotPrices,
    stage,
    error,
  } = useUpsell({ items, orders, channel, prefetchedData })

  const [showLink, setShowLink] = useState(false)

  useEffect(() => {
    if (data) {
      const cuqi = Cookies.get("show-upsell-modal")
      if (cuqi && +atob(cuqi) <= 3 && +atob(cuqi) >= 1) {
        setHookStage(+atob(cuqi) as UpsellStage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(
    () => {
      const goto = () => {
        if (!data) {
          Sentry.captureException("UpsellClient: data is empty")
          Cookies.remove("show-upsell-modal")
          document.body.style.overflow = "auto"
          setShowOrder && setShowOrder()
        }
      }
      const interval = setInterval(() => goto(), 6000)
      const interval2 = setInterval(() => setShowLink(true), 3000)
      return () => {
        clearInterval(interval)
        clearInterval(interval2)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  if (error) {
    //no need to capture expcetion here, all cases covered inside the hook
    Cookies.remove("show-upsell-modal")
    document.body.style.overflow = "auto"
    setShowOrder && setShowOrder()
  }

  const setStage = (e: UpsellStage) => {
    if (e && typeof e !== null && typeof e !== "undefined") {
      setHookStage(e)
      Cookies.set("show-upsell-modal", btoa(e.toString()), {
        expires: new Date(new Date().getTime() + 10 * 60 * 1000),
      })
    } else {
      Cookies.remove("show-upsell-modal")
      document.body.style.overflow = "auto"
      setShowOrder && setShowOrder()
    }
  }

  return data && slotData ? (
    <ErrorBoundary>
      <Suspense>
        <UpsellComponent
          isLoading={isLoading}
          isValidMethod={isValidMethod}
          modalRef={modalRef}
          setShowOrder={setShowOrder}
          setStage={setStage}
          slotData={slotData as SlotOutput}
          slotPrices={slotPrices}
          slug={channel}
          stage={stage}
        />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <PlaceholderClient
      finished={!slotData ? true : false}
      showLink={showLink}
    />
  )
}

export default UpsellClient
