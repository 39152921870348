"use client"

import { type CSSProperties, useState } from "react"
import Transition from "@local/ui/src/Transition"
import type { Payload } from "@local/payload-client/src/types"
import { useI10n } from "@local/i10n"
import { ArrowDownIcon } from "@local/ui/src/icons"

interface Props {
  data: Payload.FaqQuestion
  index: number
  totalItems: number
  style?: CSSProperties
}

export const FaqItem = ({ data, index, style, totalItems }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { currencySymbol } = useI10n()

  const text =
    typeof data.answer === "object"
      ? (data.answer as any)["en"]?.replace(
          "%%CURRENCY%%",
          `${currencySymbol}\u2060`
        )
      : data.answer?.replace("%%CURRENCY%%", `${currencySymbol}\u2060`)

  return (
    <dl
      key={data?.question}
      className={`${index! < totalItems - 1 ? "border-b border-gray-200 pb-4 mb-4" : ""}`}
    >
      <dt
        className="text-md font-medium leading-6 cursor-pointer"
        style={style}
      >
        <button
          className="flex flex-row items-center justify-between w-full text-left"
          onClick={(e) => {
            e.preventDefault()
            setIsOpen(!isOpen)
          }}
        >
          {data.question}
          {isOpen ? (
            <div style={{ transform: "rotate(180deg)" }}>
              <ArrowDownIcon />
            </div>
          ) : (
            <ArrowDownIcon />
          )}
        </button>
      </dt>
      <dd>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="scale-95 transform opacity-0"
          enterTo="scale-100 transform opacity-100"
          leave="transition ease-out duration-100"
          leaveFrom="scale-100 transform opacity-100"
          leaveTo="scale-95 transform opacity-0"
        >
          <div
            className="mt-2 text-sm text-left"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
            style={style}
          />
        </Transition>
      </dd>
    </dl>
  )
}
