export const emailProviders = [
  "gmail.com",
  "outlook.com",
  "yahoo.com",
  "aol.com",
  "me.com",
  "hotmail.com",
  "comcast.net",
  "gmx.net",
  "att.net",
  "bellsouth.net",
  "bigpond.com",
  "btinternet.com",
  "cox.net",
  "gmx.at",
  "gmx.de",
  "googlemail.com",
  "hey.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "hotmail.it",
  "icloud.com",
  "libero.it",
  "live.com",
  "live.com.au",
  "mac.com",
  "mail.ru",
  "msn.com",
  "naver.com",
  "optonline.net",
  "privaterelay.appleid.com",
  "qq.com",
  "rogers.com",
  "sbcglobal.net",
  "seznam.cz",
  "verizon.net",
  "web.de",
  "windowslive.com",
  "yandex.ru",
  "yahoo.ca",
  "yahoo.co.jp",
  "yahoo.com.au",
  "yahoo.com.hk",
  "yahoo.com.sg",
  "yahoo.com.tw",
  "yahoo.co.uk",
  "yahoo.de",
  "yahoo.es",
  "yahoo.fr",
  "yahoo.it",
  "ymail.com",
]

export const getMatchingEmailProviders = (email: string) => {
  if (!email || !email.length) return []

  if (email.includes("@")) {
    const suffix = email.split("@")[1]
    return emailProviders
      .filter((provider) => provider.startsWith(suffix))
      .map((provider) => email.split("@")[0] + "@" + provider)
  } else {
    return emailProviders.map((provider) => email + "@" + provider)
  }
}
