import { isBrowser } from "@local/utils"
import Cookies from "js-cookie"

export const getMetadata = () => {
  const allCookies = Cookies.get()
  const chills = window.$ch ?? Cookies.get("chills")
  const exponeaId = Cookies.get("__exponea_etc__")
  const fbc = Cookies.get("_fbc")
  const fbp = Cookies.get("_fbp")
  const ga = Cookies.get("_ga")
  const gcl = Cookies.get("_gcl_aw")
  const gid = Cookies.get("_gid")
  const gtmUpi = Cookies.get("gtm_upi")
  const ip = Cookies.get("pie")
    ? atob(Cookies.get("pie") as string)
    : window.$r?.ip || "0.0.0.0"
  const llvid = Cookies.get("llvid")
  const locale = Cookies.get("div-lang")
  const raclid = Cookies.get("raclid")
  const ttclid = Cookies.get("ttclid")
  const userAgent =
    navigator?.userAgent || globalThis?.navigator?.userAgent || window.$r?.ua.ua
  const vibes = isBrowser()
    ? (window.$vb ??
      window.$r?.vibes ??
      new URL(window.location.href).searchParams.get("vb"))
    : null
  const vvv = Cookies.get("vvvtwo")
  const timestamp = Date.now()
  const recart =
    window && window._recart
      ? (window._recart.getSessionId() as string)
      : Cookies.get("ghostmonitor_session_id")
  const url = window.location.href

  let gaCookies: Record<string, string> = {}
  for (let key in allCookies) {
    if (key.startsWith("_ga_")) {
      let value = allCookies[key]
      gaCookies[key] = value
    }
  }

  const channelId =
    window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string)

  const channel = ["hamstore", "cozistore", "baerskintactical"].includes(
    channelId
  )
    ? "store"
    : "lp"

  return {
    attr: {
      ...gaCookies,
      chills,
      exponea_etc: exponeaId,
      fbc,
      fbp,
      ga,
      gcl_aw: gcl,
      gid,
      gtm_upi: gtmUpi,
      ip_address: ip,
      llvid,
      raclid,
      recart,
      ttclid,
      user_agent: userAgent,
      vibes,
      url,
      vvv,
    },
    channel_id: channelId,
    channel,
    locale,
    timestamp,
    url,
    init: "customer",
  }
}
