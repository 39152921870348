import { type Currency, Price, useI10n } from "@local/i10n"
import type { SlotOutput, SlotPricesTable } from "./fetchers"
import { useOrder } from "@local/order/src/useOrder"
import { memo, useCallback, useState } from "react"
import type { Cart } from "@local/product-option-manager/types"
import { getPrimerSessionBody } from "@local/checkout/src/helpers/getPrimerSessionBody"
import type { UpsellResponse } from "./api"
import { fetchApi } from "@local/utils"
import type { CartState } from "@local/cart/src/types"
import { trackAction } from "@local/tracking/src/trackActions"
import { trackPurchase } from "@local/tracking/src/purchase"
import type { UpsellStage } from "./useUpsell"
import dynamic from "next/dynamic"
import { setId } from "@local/cart/src/id"
import { KlarnaCheckoutButton } from "@local/checkout/src/KlarnaCheckoutButton"
import * as Sentry from "@sentry/nextjs"

const PayPalExpress = dynamic(async () => {
  const mod = await import("./PayPal")
  // the button is rerendering three or four times, let's memo it
  return memo(mod.PayPalExpress)
})

interface Props {
  isLoading: boolean
  multiCart: Map<string, Cart>
  slotData: SlotOutput
  stage: UpsellStage
  setStage: (stage: UpsellStage) => void
  cartId: string
  quantity: number
  setShowOrder?: () => void
  isValidMethod: boolean
  modalRef?: React.MutableRefObject<HTMLDivElement | null>
  slotPrices: SlotPricesTable
  slug?: string | null
  localCart: CartState
  clicked: boolean
  setClicked: (clicked: boolean) => void
}

export const ActionButtons = ({
  cartId,
  clicked,
  isLoading,
  isValidMethod,
  localCart,
  modalRef,
  multiCart,
  setClicked,
  setShowOrder,
  setStage,
  slotData,
  slotPrices,
  stage,
  slug,
}: Props) => {
  const { currencyCode, locale } = useI10n()
  const { orders, addOrder, address } = useOrder()

  const order = orders?.[orders.length - 1]
  const paymentMethod = orders?.[orders.length - 1]?.method ?? "invalid"
  const isCard = paymentMethod.toUpperCase().indexOf("CARD") > -1
  const isPayPal = paymentMethod.toUpperCase().indexOf("PAYPAL") > -1
  const isStripe = paymentMethod.toUpperCase().indexOf("STRIPE") > -1
  const isKlarna = paymentMethod.toUpperCase().indexOf("KLARNA") > -1
  // const isHyper = paymentMethod.toUpperCase().indexOf("HYPER") > -1

  const [orderLoading, setOrderLoading] = useState(false)

  const acceptClick = useCallback(
    async () => {
      try {
        if (isLoading) {
          return
        }

        trackPurchase({
          cart: localCart,
          currencyCode,
        })

        trackAction("upsell_accepted", {
          currency: currencyCode as Currency,
          items: localCart.items.map((item, index) => ({
            discount: slotPrices.discount / 100, // Need to be float
            index,
            item_id: item.variantId ?? item.productId,
            item_name: item.productName,
            item_variant: item.variantName,
            price: item.price, // Need to be integer
            quantity: item.quantity,
            upsell_id: slotData.upsellId,
            upsell_slot: stage,
            upsell_version: slotData.version,
          })),
        })

        //TODO should this be here?
        // gadsConvert({
        //   currency: currencyCode as string,
        //   id: localCart.uid as string,
        //   value: (slotData?.price as number) / 100,
        // })

        const { id, uid } = setId()
        const cartToSend = {
          ...localCart,
          id,
          uid,
        }

        const body = getPrimerSessionBody({
          cart: cartToSend,
          currencyCode: currencyCode as Currency,
          email: address?.email ?? "upsell",
          locale,
          slug,
          upsell: {
            id: slotData?.upsellId as string,
            orderId: order?.uid ?? "null",
            slot: stage as number,
          },
        })

        await fetchApi<UpsellResponse>({
          endpoint: "/api/upsell/",
          method: "POST",
          data: {
            currency: currencyCode,
            body,
            orderId: order?.uid ?? "null",
            slot: (stage as number) - 1,
            upsellId: slotData?.upsellId,
          },
        })

        // Clean old cart
        await cartToSend.convertToOrder({
          cb: addOrder,
          currency: currencyCode,
          id,
          method: "card",
          uid,
        })

        modalRef && modalRef.current && modalRef.current.scrollTo(0, 0)

        if (stage !== 1) {
          setStage(null)
          setShowOrder && setShowOrder()
        } else {
          setStage(2)
        }
        setClicked(false)
      } catch (e) {
        console.error("upsell error", e)
        Sentry.captureException(`Upsell Error: ${e}`)
        setClicked(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localCart, slotData, stage]
  )

  const denyClick = async () => {
    trackAction("upsell_denied", {
      currency: currencyCode,
      items: [
        {
          discount: slotPrices.discount / 100, // It comes as integer and need to be float.
          image_url: slotData.products[0]?.images?.[0]?.image.url,
          name: slotData.products[0]?.name,
          position: 1,
          price: slotPrices.prices.get(slotData.products[0]?.id)
            ?.price as number,
          item_id: slotData.products[0]?.id,
          item_name: slotData.products[0]?.name,
          quantity: 1,
          upsell_id: slotData.upsellId,
          upsell_slot: stage,
          upsell_version: slotData.version,
          value: slotData.products[0].origPrice,
        },
      ],
    })
    localCart.reset(false)
    modalRef && modalRef.current && modalRef.current.scrollTo(0, 0)
    if (stage !== 1) {
      setStage(null)
      setShowOrder && setShowOrder()
    } else {
      setStage(3)
    }
  }

  const expressCb = useCallback(
    async (method: "paypal" | "klarna") => {
      try {
        setOrderLoading(true)

        trackAction("upsell_accepted", {
          currency: currencyCode as Currency,
          items: localCart.items.map((item, index) => ({
            discount: slotPrices.discount / 100, // It comes as integer and need to be float.
            index,
            item_id: item.variantId ?? item.productId,
            item_name: item.productName,
            item_variant: item.variantName,
            price: item.price, // Need to be an integer.
            quantity: item.quantity,
            upsell_id: slotData.upsellId,
            upsell_slot: stage,
            upsell_version: slotData.version,
          })),
        })

        await localCart.convertToOrder({
          cb: addOrder,
          currency: currencyCode,
          method,
        })
        // .then((e) => console.log(e))

        if (stage !== 1) {
          setStage(null)
          setShowOrder && setShowOrder()
        } else {
          setStage(2)
        }
      } catch (e) {
        console.error("upsell accepted error", e)
        Sentry.captureException(`Upsell Accepted Error: ${e}`)

        setClicked(false)
      } finally {
        setOrderLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencyCode, localCart, setStage, slotData, stage]
  )
  return (
    <div className="py-4">
      {!orderLoading && (
        <div className="w-full max-w-[750px] mx-auto">
          {localCart.totals.grandTotal ? (
            <p className="mt-1 mb-4 text-sm text-center">
              This will add{" "}
              <b className="text-green-800">
                <Price value={localCart.totals.grandTotal} />{" "}
              </b>
              to your order
            </p>
          ) : null}

          {isValidMethod ? (
            <>
              {(isCard || isStripe) && (
                <button
                  className="w-full px-4 py-3 font-bold text-white bg-green-600 rounded hover:opacity-80"
                  disabled={isLoading || clicked}
                  onClick={(event) => {
                    event.preventDefault()
                    acceptClick()
                    setClicked(true)
                  }}
                  id="upsell-credit-card-pay-action-button"
                >
                  {isLoading || clicked ? "Loading..." : "Add to my purchase"}
                </button>
              )}

              {isPayPal && (
                <div
                  className="relative h-[60px] overflow-hidden"
                  id="upsell-paypal-pay-action-button"
                >
                  <div className="h-[55px] bg-[#ffc439] w-full absolute flex justify-center items-center text-gray-500 cursor-not-allowed opacity-75">
                    Loading...
                  </div>

                  {/* // This neds to be standalone. Can't be imported from the Checkout one! */}
                  <PayPalExpress
                    key={JSON.stringify(multiCart.get(cartId)?.items)}
                    upsell={{
                      order: order?.uid as string,
                      id: slotData?.upsellId as string,
                      slot: stage as number,
                      cb: expressCb,
                    }}
                  />
                </div>
              )}

              {isKlarna && (
                <div
                  className="relative h-[60px] overflow-hidden"
                  id="upsell-klarna-pay-action-button"
                >
                  <KlarnaCheckoutButton
                    idSufix="upsell"
                    onSuccess={() => {
                      expressCb("klarna")
                    }}
                    hideLoading
                    upsell={{
                      orderId: order?.uid as string,
                      id: slotData?.upsellId as string,
                      slot: stage as number,
                    }}
                  />
                </div>
              )}
            </>
          ) : null}

          <button
            className="w-full px-2 py-1 mt-4 text-sm font-bold text-gray-800 underline rounded hover:opacity-80"
            disabled={isLoading}
            onClick={() => denyClick()}
            id="upsell-no-thanks-action-button"
          >
            No, I don&apos;t like more discounts
          </button>
        </div>
      )}

      {orderLoading && (
        <p className="my-16 text-xl font-semibold">
          Finalizing the purchase...
        </p>
      )}
    </div>
  )
}
