"use client"

import { useState } from "react"
import { useCart } from "@local/cart/src/useCart"
import { usePrimer } from "./usePrimer"
import { sleep } from "@local/utils/src/sleep"
import { useRouter } from "next/navigation"
import { Primer } from "@local/checkout/src/helpers/primerTypes"
import { LoadingOverlay } from "@local/ui/src/LoadingOverlay"
import { trackAction } from "@local/tracking/src/trackActions"
import { trackPurchase } from "@local/tracking/src/purchase"
import type { Klarna } from "@local/checkout/src/types"
import * as Sentry from "@sentry/nextjs"

type PaymentProps = Omit<
  Primer.HookProps,
  "onCheckoutComplete" | "onCheckoutFail"
> & { slug?: string | null }

export const Payment = ({
  currencyCode,
  email,
  phone,
  shippingAddress,
  slug,
  onSuccess,
}: PaymentProps) => {
  const cart = useCart()
  const [isSuccess, setIsSuccess] = useState(false)
  const { prefetch, push } = useRouter()

  const onCheckoutComplete =
    (gateway: "primer" | "klarna") =>
    async ({
      method,
      klarna,
    }: {
      method?: string | null
      klarna: {
        order: Klarna.CreatePaymentsOrderResponse
        body: Klarna.CreatePaymentsSessionRequest
      }
    }) => {
      let redirectUrl = `/order/redirect/${gateway}/`
      if (method) {
        redirectUrl += `?method=${method}`
      }

      if (gateway === "klarna") {
        try {
          const KLARNA_WEBHOOK_URL =
            process.env.NEXT_PUBLIC_STAGE === "stg"
              ? "https://hooks.div.haus/blfsjxvou8q83v" //channels-coin-klarna-stg
              : "https://hooks.div.haus/dctuqbdfs2doa2" //channels-coin-klarna-prd

          // Send webhook to klarna
          await fetch(KLARNA_WEBHOOK_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(klarna),
          })
        } catch (error) {
          // TODO: We should do something here, maybe cancel authorization (already on sdk) and/or retry
          console.error(error)
        }
      }

      try {
        // All conversion scripts tracking
        trackPurchase({
          cart,
          currencyCode,
        })

        // Convert to success so user waits
        setIsSuccess(true)

        // Keep pinging to get that order back
        if (!onSuccess) {
          prefetch("/order/confirm")
        }
        await sleep(600)
        if (onSuccess) {
          onSuccess({
            channel: "primer",
            method: method || undefined,
          })
          return
        }
        if (gateway === "klarna") {
          push(redirectUrl)
        } else {
          onSuccess && (onSuccess as () => void)()
        }
        // Redirect to destination
      } catch (error) {
        console.error(error)
        Sentry.captureException(
          `Checkout Error: ${error} at onCheckoutComplete`
        )
      }
    }

  const { universalCheckout } = usePrimer({
    email,
    phone,
    currencyCode,
    shippingAddress,
    slug,
    onCheckoutComplete: onCheckoutComplete("primer"),
    onCheckoutFail: (error: unknown) => {
      trackAction("payment_failed", { error })
      console.error("checkout error", error)
    },
  })

  return (
    <>
      <div id="checkout-container" />
      {!universalCheckout && (
        <p className="mx-auto my-2 font-medium text-center">
          Loading payment, please wait...
        </p>
      )}

      {isSuccess && (
        <p className="text-center">
          Please wait while we redirect you to your order
        </p>
      )}
      {isSuccess && <LoadingOverlay />}
    </>
  )
}
