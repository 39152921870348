import { CartState, GiftCartState, SingleCartItem } from "@local/cart/src/types"
import { getMetadata } from "@local/tracking/src/metadata"
import { Primer as TPrimer } from "./primerTypes"
import { highRiskEmail, highRiskIsp } from "./fraudProtect"
import { clearDecimals } from "@local/utils"
import Cookies from "js-cookie"

export interface PrimerSessionBodyProps {
  cart: CartState
  clientToken?: string | null
  currencyCode: string
  email?: string
  giftCart?: GiftCartState
  isp?: string | null
  locale: string
  phone?: string
  shippingAddress?: TPrimer.Address
  upsell?: {
    slot: number
    id: string
    orderId: string
  }
  slug?: string | null
}

interface FullOrderItem {
  amount: number
  bundleItems?: SingleCartItem[]
  description: string
  itemId: string
  name: string
  preOrder?: string | null
  quantity: number
  sku?: string | null
  type?: string
  variantName?: string | null
}

export const getPrimerSessionBody = ({
  cart,
  clientToken,
  currencyCode,
  email,
  giftCart,
  isp,
  locale,
  phone,
  slug,
  shippingAddress,
  upsell,
}: PrimerSessionBodyProps) => {
  const { totals, items, coupon, giftCard } = cart
  const cartId = cart.id ?? Cookies.get("div-cart")
  const cartUid = cart.uid ?? Cookies.get("div-cart-short")

  const couponCode = coupon?.code
  const giftCardCode = giftCard?.code
  const discountAmount = totals?.discount
    ? Math.round(totals.discount)
    : undefined
  const giftcardAmount = totals?.giftCard
    ? Math.round(totals.giftCard)
    : undefined
  const shippingCost = totals?.shipping
  const tip = totals.tip

  // Mount line items for primer body
  const lineItems = items.map((item) => ({
    quantity: item.quantity,
    itemId: (item.variantId
      ? `${item.variantId}_${item.productId}`
      : item.productId
    ).substring(0, 11), // Limit requirement for Worldline
    name: item.productName,
    description: item.variantName || item.productName, // needed for klarna to work
    amount: clearDecimals(item.price ?? 0),
    productType: item.type && item.type === "giftcard" ? "DIGITAL" : "PHYSICAL",
  })) as TPrimer.SessionBodyLineItems[]

  // Add coupon code as discount item
  if (totals?.discount && couponCode) {
    lineItems.push({
      itemId: couponCode,
      name: "Discount Coupon",
      description: couponCode,
      amount: 0,
      discountAmount: discountAmount,
      quantity: 1,
      productType: "PHYSICAL",
    })
  }

  // Add giftcard code as discount item
  if (totals?.giftCard && giftCardCode) {
    lineItems.push({
      itemId: giftCardCode,
      name: "Gift Card",
      description: giftCardCode,
      amount: 0,
      discountAmount: giftcardAmount,
      quantity: 1,
      productType: "DIGITAL",
    })
  }

  const order = {
    lineItems,
    shipping: { amount: Math.round(totals.shipping) },
    countryCode: shippingAddress?.countryCode,
  } as TPrimer.ClientSessionBodyOrder

  const fullOrder: Array<FullOrderItem> = items.map((item) => ({
    amount: Math.round(item.price!),
    bundleItems: item.bundleItems?.map((bundleItem) => bundleItem),
    description: item.variantName || item.productName, // needed for klarna to work
    itemId: item.variantId
      ? `${item.variantId}_${item.productId}`
      : item.productId,
    name: item.productName,
    preOrder: item.preOrder,
    productId: item.productId,
    productName: item.productName,
    quantity: item.quantity,
    sku: item.sku,
    type: item.type,
    variantId:
      item.bundleItems && item.bundleItems.length > 0
        ? undefined
        : item.variantId,
    variantName:
      item.bundleItems && item.bundleItems.length > 0
        ? undefined
        : item.variantName,
  }))

  // Add Bonus items for free
  if (giftCart?.bonusItems && giftCart.bonusItems.length > 0) {
    giftCart.bonusItems
      .filter((item) => item.giftAccepted)
      .forEach((item) => {
        if (item.cartItem) {
          const cartItem = item.cartItem
          lineItems.push({
            amount: 0,
            description: cartItem.variantName || cartItem.productName,
            itemId: cartItem.variantId
              ? `${cartItem.variantId}_${cartItem.productId}`
              : cartItem.productId,
            name: cartItem.productName,
            productType:
              item.type && item.type === "giftcard" ? "DIGITAL" : "PHYSICAL",
            quantity: cartItem.quantity,
          })
          fullOrder.push({
            amount: 0,
            bundleItems: undefined,
            description: cartItem.variantName || cartItem.productName,
            itemId: cartItem.variantId
              ? `${cartItem.variantId}_${cartItem.productId}`
              : cartItem.productId,
            name: cartItem.productName,
            productName: cartItem.productName,
            productId: cartItem.productId,
            preOrder: cartItem.preOrder,
            quantity: cartItem.quantity,
            sku: cartItem.sku,
            type:
              item.type && item.type === "giftcard" ? "giftcard" : "standard",
            variantName: cartItem.variantName,
            variantId: cartItem.variantId,
          } as FullOrderItem)
        }
      })
  }

  const upsellMetadata = upsell
    ? {
        upsell: upsell.orderId,
        upsell_slot: upsell.slot,
        upsell_id: upsell.id,
      }
    : {}

  const furtherChecks =
    (window?.$r?.proxy as boolean) ||
    (window?.$r?.bot as boolean) ||
    (email && highRiskEmail(email)) ||
    highRiskIsp(isp)

  const metadata = {
    ...getMetadata(),
    amountDecimal: (totals.grandTotal / 100).toFixed(2),
    cartId,
    cartUid,
    channel_id:
      slug ?? window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string),
    couponCode,
    currency: currencyCode,
    discountAmount,
    giftCard: giftCardCode,
    isp,
    locale: locale === "en" && currencyCode === "USD" ? "en-US" : locale,
    order: fullOrder,
    shippingCost,
    tip,
    ...upsellMetadata,
    furtherChecks: furtherChecks ? "1" : undefined,
  }

  const descriptor =
    `${process.env.NEXT_PUBLIC_CHANNEL_ID as string} ${cart.id?.substring(0, 3)}`.toUpperCase()

  // Mount primer body
  const body: TPrimer.ClientSessionBody = {
    amount: Math.round(totals.grandTotal),
    clientToken: clientToken || undefined,
    currencyCode,
    customerId: email ?? "unknown",
    customer: {
      billingAddress: shippingAddress || undefined,
      emailAddress: email || undefined,
      mobileNumber: phone || undefined,
      shippingAddress: shippingAddress || undefined,
    },
    metadata,
    order,
    orderId: cartId!,
    paymentMethod: {
      authorizationType: "FINAL",
      descriptor: descriptor,
      paymentType: "ECOMMERCE",
      vaultOn3DS: true,
      vaultOnSuccess: true,
    },
  }

  return body
}
