"use client"

import { OptionsSelectorSingleItemState } from "../types"
import type { ProductOptionValue } from "@local/product-option-manager/types"
import { classNames } from "@local/utils"
import styles from "./optionValue.module.css"

interface Props {
  option: ProductOptionValue
  state?: OptionsSelectorSingleItemState
  handleClick: (name: string, label: string) => void
}

export const GenericOptionsPickerSingleClient = ({
  option,
  state,
  handleClick,
}: Props) => {
  const disabled = state === OptionsSelectorSingleItemState.disabled
  const selected = state === OptionsSelectorSingleItemState.selected
  return (
    <li className="flex items-center justify-center w-full gap-2">
      <button
        onClick={(e) => {
          e.preventDefault()
          handleClick(option.name, option.localizedName)
        }}
        disabled={state === OptionsSelectorSingleItemState.disabled}
        className={classNames(
          selected ? "border-blue-500" : "border-gray-200",
          disabled ? "" : "cursor-pointer focus:outline-none",
          "relative flex items-center justify-center rounded-lg border-2 px-2 py-2 bg-gray-50 text-sm font-medium uppercase active:ring-2 active:ring-indigo-500 active:ring-offset-2 sm:flex-1 w-full",
          disabled ? styles.disabledOption : ""
        )}
      >
        <div
          className="text-sm whitespace-nowrap"
          style={{
            direction: "ltr",
          }}
        >
          {option.localizedName ?? option.name}
        </div>
      </button>
    </li>
  )
}
