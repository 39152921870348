"use client"

import { classNames } from "@local/utils"
import type { ReactNode } from "react"

interface Props {
  children?: ReactNode
  open?: boolean
  modalRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const UpsellModalClient = ({ open, children, modalRef }: Props) => {
  if (!open) {
    return null
  }

  return (
    <div
      className={classNames(
        "fixed inset-0 overflow-y-auto box-border z-[99999] bg-gray-500 bg-opacity-75 backdrop-blur-sm p-3 pb-0",
        "w-[100vw], h-[100vh] !h-[100dvh]"
      )}
      ref={modalRef}
    >
      <div className="flex w-full h-full max-w-4xl m-auto mx-auto">
        <div className="relative w-full max-w-4xl min-h-full mx-auto text-center ">
          <div className="flex flex-col items-center justify-center">
            <div className="w-full pt-3 bg-white">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
