import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
  useTransition,
} from "react"
import { CouponHandler, GiftCardHandler } from "./Coupon"
import Cookies from "js-cookie"
import { useCart } from "@local/cart/src/useCart"
import { classNames, isBrowser } from "@local/utils"
import { OrderItem } from "./OrderItem"
import { Price, useI10n } from "@local/i10n"
import { useGiftCart } from "@local/cart/src/useGiftCart"
import { CartItem } from "@local/cart/src/types"
import Link from "next/link"
import { FaqCheckout } from "./Faq"

interface Props {
  setPhase: Dispatch<SetStateAction<"shipping" | "payment">>
}

export const OrderSummary = ({ setPhase }: Props) => {
  const { currencyCode } = useI10n()
  const { addCoupon, removeCoupon, coupon, items, totals, giftCard, setError } =
    useCart()
  const { bonusItems } = useGiftCart()
  const [loaded, setLoaded] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isPending, startTransition] = useTransition()

  const initCoupon = useCallback(() => {
    if (Cookies.get("coupon_code") && !coupon) {
      addCoupon({
        code: Cookies.get("coupon_code") as string,
        currency: currencyCode,
      })
    }
  }, [addCoupon, coupon, currencyCode])

  useEffect(() => {
    startTransition(() => {
      initCoupon()
      setLoaded(true)
    })
  }, [initCoupon])

  useEffect(() => {
    if (items.some((item) => item.type === "giftcard")) {
      removeCoupon()
      setError(
        "Discount coupons cannot be applied to the purchase of gift cards."
      )
    }
  }, [items, removeCoupon, setError])

  if (!loaded || !isBrowser() || isPending) {
    return null
  }

  // Total for giftcard calc
  const orderTotal =
    totals.subTotal +
    totals.shipping +
    (totals.tip || 0) -
    (totals.discount || 0)

  return (
    <div className="sm:sticky sm:top-8">
      <div className="flex items-center justify-end sm:justify-between">
        <h2 className="hidden pb-2 text-3xl font-semibold text-zinc-900 sm:block">
          Order Summary
        </h2>
        <Link href="/cart">Edit cart</Link>
      </div>
      <div
        className="mt-4 bg-white border rounded-sm shadow-sm border-zinc-200"
        onClick={() => setIsOpen(true)}
      >
        <h3 className="sr-only">Items in your cart</h3>

        <div
          className={classNames(
            "space-y-6",
            isOpen ? "block" : "hidden sm:block"
          )}
        >
          <ul role="list" className="m-1 divide-y divide-zinc-200">
            {items?.map((item, i) => (
              <OrderItem
                key={`order-summary-${i}-${item?.productId}`}
                item={item}
                index={i}
              />
            ))}
            {bonusItems?.map(
              (item, i) =>
                item.giftAccepted &&
                item.cartItem && (
                  <OrderItem
                    key={`order-summary-${i}-${item?.id}`}
                    item={
                      {
                        ...item.cartItem,
                        image: item.image,
                        // image: (() =>
                        //   item?.variants?.find((e) => {
                        //     return item?.cartItem?.variantId === e.id
                        //   })?.image?.url || item.images?.[0]?.image.url)(),
                        price: 0,
                        prices: [],
                        quantity: 1,
                        slug: "", //TODO: needs slug
                      } as CartItem
                    }
                    index={i}
                    isGift
                  />
                )
            )}
          </ul>
        </div>

        <div className={classNames(isOpen ? "block" : "hidden sm:block")}>
          {!items.some((item) => item.type === "giftcard") && (
            <>
              <div className="py-5 mx-4 border-t border-zinc-200 sm:mx-5">
                <CouponHandler setPhase={setPhase} />
              </div>

              <div className="py-5 mx-4 border-t border-zinc-200 sm:mx-5">
                <GiftCardHandler setPhase={setPhase} />
              </div>
            </>
          )}
        </div>

        <div className="py-6 mx-4 border-t border-zinc-200 sm:mx-6">
          <div
            className={classNames(
              isOpen ? "block -space-y-1.5" : "hidden sm:block"
            )}
          >
            <dl className="flex items-center justify-between">
              <dt className="text-sm">Subtotal:</dt>
              <dd className="text-sm font-semibold text-zinc-900">
                <Price value={totals.subTotal} />
              </dd>
            </dl>

            <dl className="flex justify-between pt-1.5">
              <dt className="text-sm">Shipping:</dt>
              <dd className="text-sm font-semibold tracking-tight text-zinc-900">
                {totals.shipping == 0 ? (
                  "FREE"
                ) : (
                  <Price value={totals.shipping} />
                )}
              </dd>
            </dl>

            {totals?.tip && totals?.tip > 0 ? (
              <dl className="flex justify-between pt-1.5">
                <dt className="text-sm">Donation:</dt>
                <dd className="text-sm font-semibold tracking-tight text-zinc-900">
                  {totals.tip == 0 ? "NONE" : <Price value={totals.tip} />}
                </dd>
              </dl>
            ) : (
              ""
            )}

            {coupon && totals?.discount && (
              <dl className="flex items-center justify-between">
                <dt className="text-sm">Discount Coupon - {coupon.code}</dt>
                <dd className="text-sm font-semibold text-green-600">
                  {" "}
                  -<Price value={totals.discount} />
                </dd>
              </dl>
            )}

            {giftCard && totals.giftCard && (
              <dl className="flex items-center justify-between ">
                <dt className="text-sm">Gift Card - {giftCard.code}</dt>
                <dd className="text-sm font-semibold text-green-600">
                  {" "}
                  -
                  <Price
                    value={
                      orderTotal < totals.giftCard
                        ? orderTotal
                        : totals.giftCard
                    }
                  />
                </dd>
              </dl>
            )}
          </div>

          <dl
            className={classNames(
              "flex items-center justify-between mt-2 mb-1",
              isOpen
                ? "border-t border-zinc-200 pt-6"
                : "pt-0 sm:mt-6 sm:border-t sm:border-zinc-200 sm:pt-6"
            )}
          >
            <dt className="text-sm font-semibold">
              Total:
              <span
                className={classNames(
                  isOpen
                    ? "hidden"
                    : "ml-2 inline cursor-pointer text-xs text-zinc-600 sm:hidden sm:cursor-auto"
                )}
              >
                Click for more details
              </span>
            </dt>
            <dd className="flex items-center text-sm font-semibold flex-inline text-zinc-900">
              <Price value={totals.grandTotal} />
            </dd>
          </dl>
        </div>
      </div>
      <span
        onClick={() => setIsOpen(false)}
        className={classNames(
          !isOpen
            ? "hidden"
            : "ml-2 inline cursor-pointer font-semibold text-xs text-zinc-600 sm:hidden sm:cursor-auto"
        )}
      >
        Click to hide details
      </span>
      <div className="hidden lg:block">
        <FaqCheckout />
      </div>
    </div>
  )
}

export default OrderSummary
