export const ArrowDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.188em"
    height="1.063em"
    viewBox="0 0 35 17"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2225 5.86244C23.5925 6.2324 23.5925 6.83225 23.2225 7.20218L18.1699 12.2548C17.7999 12.6248 17.2001 12.6248 16.8302 12.2548L11.7775 7.20218C11.4075 6.83225 11.4075 6.2324 11.7775 5.86244C12.1474 5.49247 12.7473 5.49247 13.1173 5.86244L17.5 10.2451L21.8828 5.86244C22.2528 5.49247 22.8525 5.49247 23.2225 5.86244Z"
      fill="currentColor"
    />
  </svg>
)

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width="1em"
    height="1em"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
  </svg>
)

export const MinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width="1em"
    height="1em"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
  </svg>
)
