import { Currency } from "@local/i10n"
import {
  bingConvert,
  fbqConvert,
  nbpixConvert,
  pinterestConvert,
  rumbleConvert,
  ttqConvert,
  twitterConvert,
} from "./scripts"
import type { Cart } from "@local/cart/src/types"
import { trackAction, trackSnapchat } from "./trackActions"
import { identifyUser } from "./identify"
import { clearDecimals, clearRandomIdsFromCartBundles } from "@local/utils"
import { captureException } from "@sentry/nextjs"

interface TrackPurchaseInput {
  currencyCode?: Currency | string | null
  cart: Cart
  identify?: {
    email?: string | null
    phone?: string | null
  }
}

export const trackPurchase = ({
  cart,
  currencyCode,
  identify,
}: TrackPurchaseInput) => {
  const { uid, totals, coupon, items } = clearRandomIdsFromCartBundles(cart)
  const currency = currencyCode ?? "USD"
  const channelId = process.env.NEXT_PUBLIC_CHANNEL_ID

  // GADS conversion
  // Should be first thing
  try {
    if (
      window &&
      window.gtag &&
      channelId &&
      !["ham", "hamstore", "cozistore", "cozislides"].includes(channelId)
    ) {
      window.gtag("event", "conversion", {
        send_to: "AW-16555328298/WDx7CJHDv9AZEKqGmdY9",
        value: clearDecimals(Math.round((totals.grandTotal / 100) * 0.6), 0),
        currency,
        transaction_id: uid,
      })
      window.gtag("event", "conversion", {
        send_to: "AW-16555328298/4V4hCOybhdAZEKqGmdY9",
        value: clearDecimals(Math.round((totals.grandTotal / 100) * 0.6), 0),
        currency,
        transaction_id: uid,
      })
      window.gtag("event", "conversion", {
        send_to: "AW-16555328298/UanQCMXG68oZEKqGmdY9",
        value: clearDecimals(Math.round((totals.grandTotal / 100) * 0.6), 0),
        currency,
        transaction_id: uid,
      })
    }
  } catch (e) {
    console.error("fbq not loaded")
    captureException("Could not send gads conversion")
  }

  // GADS conversion
  // This is now deprecated as of:
  // https://divbrands.slack.com/archives/CRVEQNKN0/p1718129402352469?thread_ts=1717493234.865479&cid=CRVEQNKN0
  // gadsConvert({
  //   currency,
  //   id: uid as string,
  //   value: totals.grandTotal / 100,
  // })

  if (identify) {
    identifyUser({ email: identify.email, phone: identify.phone })
  }

  // GA4 Purchase tracking
  trackAction("purchase", {
    email: identify ? identify.email : undefined,
    coupon: coupon ? coupon.code : undefined,
    currency,
    shipping: clearDecimals(totals.shipping / 100),
    tax: clearDecimals(totals.tax / 100),
    transaction_id: uid,
    value: clearDecimals(totals.grandTotal / 100),
    items: items.map((item, index) => ({
      affiliation: channelId,
      coupon: coupon ? coupon.code : undefined,
      index,
      item_id: item.variantId ? item.variantId : item.productId,
      item_name: item.productName,
      item_variant: item.variantName,
      price: item.price ? clearDecimals(item.price / 100) : undefined,
      quantity: item.quantity,
    })),
  })

  // Meta conversion event
  fbqConvert({
    currency,
    id: uid as string,
    value: totals.grandTotal / 100,
    productIds: items.map((item) =>
      item.variantId ? item.variantId : item.productId
    ),
  })

  // TikTok conversion event
  ttqConvert({
    value: totals.grandTotal / 100,
    currency: currencyCode as string,
    items: items.map((item) => ({
      id: item.variantId ?? item.productId,
      name: item.productName,
      quantity: item.quantity,
    })),
  })

  // Bing conversion event
  bingConvert({
    value: totals.grandTotal / 100,
    currency,
  })

  // Rumble conversion event
  rumbleConvert({
    value: totals.grandTotal / 100,
  })

  pinterestConvert({
    value: totals.grandTotal / 100,
    currency: currencyCode as string,
    items: items.map((item) => ({
      id: item.variantId ?? item.productId,
      name: item.productName,
      quantity: item.quantity,
    })),
  })

  twitterConvert({
    value: totals.grandTotal / 100,
    currency: currencyCode as string,
    email: identify && identify.email ? identify.email : null,
  })

  // Newsbreak Pixel
  nbpixConvert()

  // Live Intent
  // trackLiveIntent("conversion", {
  //   name: "product_purchase",
  //   email: identify && identify.email ? identify.email : null,
  //   conversionId: uid,
  //   amount: (Math.round(totals.grandTotal * 0.6) / 100).toFixed(2),
  //   currency: currency,
  //   items: items.map((item) => ({
  //     id: item.variantId ?? item.productId,
  //     quantity: item.quantity,
  //     price: (Math.round((item?.price ?? 0) * 0.6) / 100).toFixed(2),
  //     currency,
  //   })),
  // })

  trackSnapchat("PURCHASE", {
    price: Math.round((totals.grandTotal * 0.6) / 100).toFixed(2),
    currency,
    item_ids: items.map((item) => item.variantId ?? item.productId),
    number_items: items.length,
    user_email: identify && identify.email ? identify.email : null,
    user_phone_number: identify && identify.phone ? identify.phone : null,
  })
}
