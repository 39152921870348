import { isBrowser } from "./isBrowser"

interface Handle {
  value: number
}

/**
 * Function to create an interval that executes a callback function every `ms` milliseconds.
 * Tailored to substitute `setInterval` and `clearInterval` with `requestAnimationFrame`.
 * Counts time relative to when the page started and does not drift.
 * It will run as soon as the browser is ready to paint, which is typically 16.7ms.
 * @param {function} callback - The function to be executed every `ms` milliseconds.
 * @param {number} ms - The interval in milliseconds.
 * @returns {Handle} - Returns a handle object that can be used to clear the interval.
 * @example
 * ```ts
 * const handle = requestInterval(() => {
 *  console.log("Hello, World!")
 * }, 1000)
 * ```
 */
export const requestInterval = (callback: () => void, ms: number) => {
  if (!isBrowser() && !window?.requestAnimationFrame) return

  let start = new Date().getTime()
  const handle = {} as Handle

  const requestAnimFrame = (() => {
    return window?.requestAnimationFrame
  })()

  function loop() {
    handle.value = requestAnimFrame(loop)
    var current = new Date().getTime(),
      delta = current - start
    if (delta >= ms) {
      callback()
      start = new Date().getTime()
    }
  }

  handle.value = requestAnimFrame(loop)
  return handle
}

/**
 * Function to clear the interval created by `requestInterval`.
 * @param handle - The handle object returned by `requestInterval`.
 * @returns {void}
 * @example
 * ```ts
 * const handle = requestInterval(() => {
 * console.log("Hello, World!")
 * }, 1000)
 * clearRequestInterval(handle)
 * ```
 */
export const clearRequestInterval = (handle?: Handle) => {
  if (!handle && !isBrowser() && !window?.cancelAnimationFrame) return
  window.cancelAnimationFrame(handle!.value)
}
