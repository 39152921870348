import { validateUsZip } from "@local/i10n/src/UsBases"
import * as z from "zod"

const alphabeticRegex = /^([^0-9]*)$/
const numericRegex = /^[0-9]+$/

export const validationSchema = () =>
  z
    .object({
      line1: z.string().min(1, { message: "Address line 1 is required" }),
      line2:
        z.string() /*.min(1, { message: "House/Unit Number is required" })*/,
      city: z.string().min(1, { message: "City is required" }),
      country: z.string().min(1, { message: "Country is required" }),
      email: z
        .string()
        .min(1, { message: "Email is required" })
        .email("Email is invalid"),
      first_name: z
        .string()
        .min(1, { message: "Your first name is required" })
        .refine((value) => alphabeticRegex.test(value), {
          message: "Your first name must contain only letters",
        }),
      last_name: z
        .string()
        .min(1, { message: "Your last name is required" })
        .refine((value) => alphabeticRegex.test(value), {
          message: "Your last name must contain only letters",
        }),
      phone: z
        .string()
        .min(1, { message: "Phone is required" })
        .min(5, { message: "Please enter a valid phone number" })
        .refine((value) => numericRegex.test(value), {
          message: "Your phone must contain only numbers",
        }),
      state: z.string().optional(),
      postal_code: z
        .string()
        .min(4, { message: "ZIP Code is required" })
        .max(12),
    })
    .superRefine((values, ctx) => {
      // Zip validation
      if (
        ["US"].includes(values.country) &&
        !validateUsZip(values.postal_code)
      ) {
        ctx.addIssue({
          message: "We cannot deliver to this ZIP code",
          code: z.ZodIssueCode.custom,
          path: ["zip"],
        })
      }

      // State validation
      if (!["GB", "DE"].includes(values.country) && !values.state?.length) {
        ctx.addIssue({
          message: "Field is required",
          code: z.ZodIssueCode.custom,
          path: ["state"],
        })
      }
    })

export const noPostCodeCountries = [
  { code: "AO", name: "Angola" },
  { code: "AG", name: "Antigua and Barbuda" },
  { code: "AW", name: "Aruba" },
  { code: "SH-AC", name: "Ascension island" }, // Non-standard code
  { code: "BS", name: "Bahamas" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BW", name: "Botswana" },
  { code: "BO", name: "Bolivia" },
  { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "CM", name: "Cameroon" },
  { code: "CF", name: "Central African Republic" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo" },
  { code: "CD", name: "Congo the Democratic Republic of the" },
  { code: "CK", name: "Cook Islands" },
  { code: "CI", name: "Cote d’Ivoire" },
  { code: "CW", name: "Curaçao" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "TL", name: "East Timor" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "FJ", name: "Fiji" },
  { code: "TF", name: "French Southern Territories" },
  { code: "GM", name: "Gambia" },
  { code: "GA", name: "Gamon" }, // Placeholder code, no actual country
  { code: "GH", name: "Ghana" },
  { code: "GD", name: "Grenada" },
  { code: "GY", name: "Guyana" },
  { code: "HM", name: "Heard and McDonald Islands" },
  { code: "HK", name: "Hong Kong" },
  { code: "KI", name: "Kiribati" },
  { code: "LY", name: "Libya" },
  { code: "MO", name: "Macau" },
  { code: "MW", name: "Malawi" },
  { code: "ML", name: "Mali" },
  { code: "MR", name: "Mauritania" },
  { code: "NR", name: "Nauru" },
  { code: "AN", name: "Netherlands Antilles" }, // Obsolete code
  { code: "NU", name: "Niue" },
  { code: "KP", name: "North Korea" },
  { code: "QA", name: "Qatar" },
  { code: "RW", name: "Rwanda" },
  { code: "KN", name: "Saint Kitts and Nevis" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "ST", name: "Sao Tome and Principe" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SR", name: "Suriname" },
  { code: "SY", name: "Syria" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "VU", name: "Vanuatu" },
  { code: "YE", name: "Yemen" },
  { code: "ZW", name: "Zimbabwe" },
]
