import { classNames } from "@local/utils/src/classNames"
import { OptionsSelectorSingleItemState } from "../types"
import type { ProductOptionValue } from "@local/product-option-manager/types"
import Image from "next/image"
import style from "./optionValue.module.css"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
import Camo from "./images/camo.svg"
interface ColorPickerProps {
  option: ProductOptionValue
  label?: string
  state?: OptionsSelectorSingleItemState | null
  handleClick: (name: string, label: string) => void
}

export const ColorPickerSingleClient = ({
  option,
  state = OptionsSelectorSingleItemState.default,
  handleClick,
}: ColorPickerProps) => {
  const disabled = state === OptionsSelectorSingleItemState.disabled
  const isCamo = option.name.toLowerCase() === "camo"

  return (
    <li className="w-full">
      <button
        onClick={(e) => {
          e.preventDefault()
          !disabled && handleClick(option.name, option.localizedName)
        }}
        disabled={disabled}
        className={classNames(
          "relative flex items-center justify-center rounded-lg border-2 px-2 py-2 w-full bg-gray-50",
          state === OptionsSelectorSingleItemState.selected
            ? "border-blue-500"
            : "border-gray-200",
          disabled ? "" : "cursor-pointer focus:outline-none",
          disabled ? style.disabledOption : ""
        )}
      >
        <div
          aria-hidden="true"
          className={classNames(
            "min-w-4 mr-1 block !h-4 !w-4 rounded-full",
            isCamo ? "" : "border border-black border-opacity-20"
          )}
          style={{
            backgroundColor: option.color,
            backgroundSize: "cover",
          }}
        >
          {isCamo ? (
            <Image
              src={Camo}
              alt="camo"
              className="object-cover w-4 h-4 rounded-full"
              width={16}
              height={16}
              loader={
                process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined
              }
            />
          ) : (
            ""
          )}
        </div>
        <p className="mr-1 text-sm">{option.localizedName}</p>
      </button>
    </li>
  )
}
