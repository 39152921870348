interface QuantitySelectorProps {
  quantity: number
  handleChange: (quantity: number) => void
}

export const AddQuantity = ({
  quantity,
  handleChange,
}: QuantitySelectorProps) => {
  return (
    <button
      className="rounded-full border-[2px] border-blue-500 px-12 py-3 mt-4 leading-none"
      onClick={() => handleChange(quantity + 1)}
    >
      Add 1 More
    </button>
  )
}
