const knownFraudsters = [
  "areaxwry",
  "baypea348",
  "examplecom",
  "garyl854",
  "generated_email_us",
  "grisbelvasquez586",
  "itbyeten",
  "jennydilone",
  "nunmrare",
  "perfryowl",
  "rickil854",
  "yonairaramos",
]

const problematicIsps = ["Latitude.sh"]

export const checkFraudEmail = (email: string) => {
  const cleanedMail = email.replaceAll(".", "")
  const isFraudster = knownFraudsters.some((fraudster) =>
    cleanedMail.includes(fraudster)
  )
  return isFraudster
}

export const highRiskIsp = (isp?: string | null) => {
  const shouldChallenge = isp ? problematicIsps?.includes(isp) : false
  return shouldChallenge
}

/**
 * Checks if the email contains a dot in the first half, which is a fingerprint of fraudsters
 * @param email
 * @returns boolean
 */
export const highRiskEmail = (email: string) => {
  const splitEmail = email.split("@")
  const firstHalf = splitEmail[0]

  return firstHalf.indexOf(".") > -1
}
