import { BonusCartItem, CartState } from "@local/cart/src/types"
import { getMetadata } from "@local/tracking/src/metadata"
import { Primer as TPrimer } from "./primerTypes"

export interface FreeOrderSessionBodyProps {
  cart: CartState
  bonusItems: BonusCartItem[]
  currency: string
  email: string
  locale: string
  phone: string
  shipping: TPrimer.Address
}

export interface FreeOrderSessionBody {
  email: string
  phone: string
  currency: string
  shipping: TPrimer.Address
  metadata: {
    [key: string]: any
  }
}

export const getFreeOrderSessionBody = ({
  cart,
  bonusItems,
  currency,
  email,
  locale,
  phone,
  shipping,
}: FreeOrderSessionBodyProps) => {
  const { id: cartId, totals, uid, items, coupon, giftCard } = cart
  const couponCode = coupon?.code
  const giftCardCode = giftCard?.code
  const discountAmount = totals?.discount
    ? Math.round(totals.discount)
    : undefined
  const shippingCost = totals?.shipping
  const tip = totals.tip

  // Mount line items for primer body
  const lineItems = items.map((item) => ({
    quantity: item.quantity,
    itemId: (item.variantId
      ? `${item.variantId}_${item.productId}`
      : item.productId
    ).substring(0, 11), // Limit requirement for Worldline
    name: item.productName,
    description: item.variantName || item.productName, // needed for klarna to work
    amount: Math.round(item.price!),
    productType:
      item.type && item.type === "giftcard" ? "GIFTCARD" : "PHYSICAL",
  })) as TPrimer.SessionBodyLineItems[]

  // Add coupon code as discount item
  if (totals?.discount && couponCode) {
    lineItems.push({
      itemId: couponCode,
      name: "Discount Coupon",
      description: couponCode,
      amount: 0,
      discountAmount: discountAmount,
      quantity: 1,
      productType: "PHYSICAL",
    })
    // lineItems[0].discountAmount = discountAmount
  }

  const order = {
    lineItems,
    shipping: { amount: Math.round(totals.shipping) },
    countryCode: shipping.countryCode,
  } as TPrimer.ClientSessionBodyOrder

  const fullOrder = items.map((item) => ({
    amount: Math.round(item.price!),
    bundleItems: item.bundleItems?.map((bundleItem) => bundleItem),
    description: item.variantName || item.productName,
    itemId: item.variantId
      ? `${item.variantId}_${item.productId}`
      : item.productId,
    name: item.productName,
    productName: item.productName,
    productId: item.productId,
    quantity: item.quantity,
    sku: item.sku,
    type: item.type,
    variantName: item.variantName,
    variantId: item.variantId,
  }))

  // Add Bonus items for free
  if (bonusItems && bonusItems.length > 0) {
    bonusItems.forEach((bonusItem) => {
      if (bonusItem.cartItem) {
        const item = bonusItem.cartItem
        lineItems.push({
          quantity: item.quantity,
          itemId: item.variantId
            ? `${item.variantId}_${item.productId}`
            : item.productId,
          name: item.productName,
          description: item.variantName || item.productName,
          amount: Math.round(item.price!),
          productType: bonusItem.type === "giftcard" ? "DIGITAL" : "PHYSICAL",
        })
        fullOrder.push({
          amount: Math.round(item.price!),
          bundleItems: undefined,
          description: item.variantName || item.productName,
          itemId: item.variantId
            ? `${item.variantId}_${item.productId}`
            : item.productId,
          name: item.productName,
          productId: item.productId,
          productName: item.productName,
          quantity: item.quantity,
          sku: item.sku,
          type: "standard",
          variantName: item.variantName,
          variantId: item.variantId,
        })
      }
    })
  }

  const metadata = {
    ...getMetadata(),
    // Fallback for multi setup
    channelId: window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string),
    currency,
    locale,
    order: fullOrder,
    shippingCost,
    discountAmount,
    couponCode,
    giftCard: giftCardCode,
    cartId,
    cartUid: uid,
    tip,
  }

  // Mount primer body
  const body: FreeOrderSessionBody = {
    email,
    phone,
    shipping,
    currency,
    metadata,
  }

  return body
}
