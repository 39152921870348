import { CartItem } from "@local/cart/src/types"
import { Price } from "@local/i10n"
import Image from "next/image"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
import { PreOrder } from "./PreOrder"

interface Props {
  item: CartItem
  isGift?: boolean
  index: number
}

export const OrderItem = ({ item, isGift, index }: Props) => {
  return (
    <li className="flex px-4 py-6 sm:px-6">
      <div className="flex-shrink-0 w-20">
        <Image
          alt={item?.productName as string}
          height={256}
          src={item.image!}
          width={256}
          loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined}
        />
      </div>

      <div className="flex flex-col flex-1 ml-6">
        <div className="flex">
          <div className="flex-1 min-w-0">
            <h4 className="font-semibold text-zinc-700 hover:text-zinc-800">
              {item?.productName}
            </h4>
            {item.variantName && (
              <p className="mt-0.5 text-sm text-gray-500">
                {item?.variantName}
              </p>
            )}
            {item?.bundleItems?.length ? (
              <p className="leading-4 text-xxs text-slate-400">
                {item.bundleItems.map((bundle, i) => (
                  <>
                    {bundle.productName}&nbsp;
                    {bundle.variantName}
                    {i < (item.bundleItems?.length || 0) - 1 ? <br /> : null}
                  </>
                ))}
              </p>
            ) : null}
          </div>
        </div>

        <div className="flex flex-row items-center justify-start gap-2 my-2 align-middle">
          <p className="text-sm font-medium text-zinc-700">
            {isGift ? (
              "FREE GIFT"
            ) : (
              <>
                <Price value={item?.price || 0} />
                /each
              </>
            )}
          </p>
          <p className="text-sm font-medium text-zinc-900">
            {!isGift && (
              <Price value={(item?.price as number) * item.quantity || 0} />
            )}
          </p>
        </div>

        <div className="mt-0.5 flex content-end text-sm text-gray-400">
          <label htmlFor="quantity">Qty</label>
          <p id="quantity" className="ml-1 text-base text-left sm:text-sm">
            {item?.quantity}
          </p>
        </div>

        <div className="my-1 text-gray-600">
          <PreOrder date={item.preOrder} />
        </div>
      </div>
    </li>
  )
}
