"use client"

import {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
  type MouseEvent,
} from "react"
import { classNames } from "@local/utils/src/classNames"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { OrderSummary } from "./components/OrderSummary"
import { useI10n } from "@local/i10n/src/useI10n"
import { useRouter } from "next/navigation"
import {
  MemoTrackScript,
  trackAction,
  useTracking,
} from "@local/tracking/src/trackActions"
import { TrustIcons } from "./components/TrustIcons"
import { useCart } from "@local/cart/src/useCart"
import { useIP } from "@local/tracking/src/useIP"
import { ErrorBoundary } from "./ErrorBoundary"
import {
  AustraliaTerritories,
  CanadaProvinces,
  NewZealandRegions,
  UkCountries,
  UsStates,
} from "@local/i10n/src/states"
import { CountriesWithPriorityOnTop, Price, RegionSelector } from "@local/i10n"
import { Payment } from "@local/checkout/src/components/Payment"
import { EmailComplete, TextField } from "./components"
import type { TCheckoutFormValues } from "./types"
import type { Primer } from "@local/checkout/src/helpers/primerTypes"
import styles from "./styles.module.css"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import {
  noPostCodeCountries,
  validationSchema,
} from "./helpers/validationSchema"
import Step, { CheckoutStep } from "./components/Step"
import useFormPersist from "react-hook-form-persist"
import dynamic from "next/dynamic"
import PhoneInput from "./components/PhoneInput"
import { clearDecimals, useLocalStorage } from "@local/utils"
import { useOrder } from "@local/order/src/useOrder"
import { getFreeOrderSessionBody } from "@local/checkout/src/helpers/getFreeOrderSessionBody"
import { trackPurchase } from "@local/tracking/src/purchase"
import Cookies from "js-cookie"
import { createPortal } from "react-dom"
import { UpsellModalClient } from "@local/upsell/src/Modal"
import { UpsellPopupClient } from "./UpsellPopup"
import { phone } from "phone"
import type { UpsellStage } from "@local/upsell/src/useUpsell"
import useGiftCart from "@local/cart/src/useGiftCart"
import { checkFraudEmail } from "@local/checkout/src/helpers/fraudProtect"
import { identifyUser } from "@local/tracking/src/identify"
import { APIProvider } from "@vis.gl/react-google-maps"
import { Autocomplete } from "./components/Autocomplete"
import { FaqCheckout } from "./components/Faq"
import { getCountryFromCurrency } from "@local/i10n/src/getCountryFromCurrency"
import Link from "next/link"
import { getUpsell, UpsellOutput } from "@local/upsell/src/fetchers"
import { PaypalProviderWrapper } from "./paypalWrapper"
import { fetchApi } from "@local/utils/src/fetchApi"

const Tipping = dynamic(
  async () => {
    const mod = await import("./components/Tipping")
    return mod.Tipping
  },
  {
    ssr: false,
  }
)

const Hyperswitch = dynamic(
  async () => {
    const mod = await import("./components/Hyperswitch")
    return mod.Hyperswitch
  },
  {
    ssr: false,
  }
)

const DirectExpressCheckout = dynamic(
  async () => {
    const mod = await import("./components/DirectExpressCheckout")
    return mod.DirectExpressCheckout
  },
  {
    ssr: false,
  }
)

const EmptyCartFallback = () => (
  <div>
    <div className="py-4 text-center bg-opacity-50 bg-gradient-to-b from-transparent to-yellow-100 rounded-xl">
      <p className="text-[60px] leading-none">🏜️</p>
      <span className="text-lg font-bold">Your cart is empty...</span>
    </div>
  </div>
)

export const Checkout = ({
  expressDisabled,
  trustPilotSlider,
  emptyCartComponent,
}: {
  expressDisabled?: boolean
  trustPilotSlider?: JSX.Element | null
  emptyCartComponent?: JSX.Element | null
}) => {
  const { country: i10nCountry, currencyCode, init, isUSA } = useI10n()

  const [phase, setPhase] = useState<"shipping" | "payment">("shipping")
  const [expressClicked, setExpressClicked] = useState(false)
  const [country, setCountry] = useState(i10nCountry.toUpperCase())

  const [phoneCountry, setPhoneCountry] = useLocalStorage(
    "phoneCountry",
    // This is to avoid EU as country comming from our sources
    i10nCountry && i10nCountry.toUpperCase() !== "EU"
      ? i10nCountry.toUpperCase()
      : window.$r?.geo?.country
        ? window.$r?.geo?.country.toUpperCase()
        : "US"
  )

  const { setAddress } = useOrder()
  const [isLocSet, setIsLocSet] = useState(false)

  const cart = useCart()
  const { addOrder } = useOrder()
  const { items, totals, coupon } = cart
  const { bonusItems } = useGiftCart()
  const { push } = useRouter()
  const { loc } = useIP()
  const [submitFree, setSubmitFree] = useState(false)
  const [showUpsellModal, setShowUpsellModal] = useState({
    show: false,
    stage: null as UpsellStage,
    method: null as "card" | "paypal" | "stripe" | "klarna" | "hyper" | null,
  })
  const [hasTextConsent, setTextConsent] = useState(true)

  const { trackActionBuffered } = useTracking()

  const modalRef = useRef(null)
  const shippingAddressTracked = useRef<boolean>(false)

  const {
    formState: { errors, isSubmitting, isSubmitSuccessful, dirtyFields },
    clearErrors,
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<TCheckoutFormValues>({
    resolver: zodResolver(validationSchema()),
    context: { country },
    defaultValues: {
      country:
        i10nCountry && i10nCountry.toUpperCase() !== "EU"
          ? i10nCountry.toUpperCase()
          : window.$r?.geo?.country
            ? window.$r?.geo?.country.toUpperCase()
            : "US",
    },
  })

  useFormPersist("checkout-form", {
    watch,
    setValue,
    storage: typeof window !== "undefined" ? window.localStorage : undefined, // default window.sessionStorage
  })

  // Effect to use the CART CURRENCY as source of truth.
  // Fix the "traveller's exploit" where user adds something to cart in the US
  // and then buys in Canada. Cart keeps USD value, but currency is CAD. 💸
  useEffect(() => {
    const currencyFromCookie = Cookies.get("div-currency")
    if (cart.currency && cart.currency !== currencyFromCookie) {
      const country = getCountryFromCurrency(cart.currency)
      init(country, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.currency])

  // reset cart if upsell modal is closed & cart is upsell to avoid low price leaks
  // anchor doesn't need as cart will be overriden at visit
  const { isUpsellCart, reset: resetCart } = useCart()

  useEffect(() => {
    if (isUpsellCart && showUpsellModal.show === false) {
      // special situations here:
      // if the cart is upsell but no modal should be open,
      // means user has bought something and lost the opportunity to upsell,
      // OR user is messing with multiple tabs. Either way, redirect to order confirm.
      // no need to validate channel.
      // this can replace that silly "mayday" message

      resetCart()
      push("/order/confirm/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCart, isUpsellCart, showUpsellModal])

  const channel =
    typeof window !== "undefined" && window.channel
      ? window.channel
      : (process.env.NEXT_PUBLIC_CHANNEL_ID as string)

  //prefetch upsell data
  const getUpsellData = async () => {
    let mostExpensiveProduct = null
    let upsellTypeId: "single" | "multi" = "single"

    // Figure out the most expensive product from cart
    if (items.length > 0) {
      const sortedProducts = items.sort((a, b) => {
        if (a.price === b.price) {
          return -1
        }
        return a.price! - b.price!
      })
      mostExpensiveProduct = sortedProducts[sortedProducts.length - 1]

      if (sortedProducts.length > 1 || sortedProducts[0].quantity > 1) {
        upsellTypeId = "multi"
      }
    }

    const mostExpensiveProductId = mostExpensiveProduct?.productId ?? null

    const res = await getUpsell({
      channel,
      productId: mostExpensiveProductId,
      type: upsellTypeId,
    })
    if (res) {
      return res
    }
  }

  const [prefetchedUpsellData, setPrefetchedUpsellData] = useState<
    UpsellOutput | undefined
  >()

  useEffect(() => {
    if (showUpsellModal.show || isUpsellCart) {
      return
    } else if (expressClicked || phase === "payment") {
      getUpsellData().then((res) => setPrefetchedUpsellData(res))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, expressClicked, showUpsellModal, isUpsellCart])
  //end prefetch upsell data

  useEffect(() => {
    if (showUpsellModal.show) {
      return
    }
    // if country is set, we should leave the form alone.
    const country = getValues("country")
    country && setCountry(country)

    if (!country && loc && !isLocSet && loc.countryCode) {
      // Since we will set country, we need to reset state.
      if (!["US"].includes(loc.countryCode)) {
        setValue("state", "")
      }

      setCountry(loc.countryCode)
      setValue("country", loc.countryCode)

      // TODO: beware of the cloudfront texas bug! Should keep an eye on this.
      // this is the spot where it used to set the state wrongly to Texas.
      if (["US", "CA", "AU"].includes(loc.countryCode)) {
        setValue("state", loc.regionName)
      }

      // Assure that state is reset for GB and DE
      if (["GB", "DE"].includes(loc.countryCode)) {
        setValue("state", "")
      }

      // If the phone field is filled, it means it was persisted and we shouldn't change that back.
      if (getValues("phone") === "") {
        setPhoneCountry(loc.countryCode)
      }

      setIsLocSet(true)
    }

    //don't add "country" below!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, isLocSet, loc, setPhoneCountry, setValue, showUpsellModal])

  const [contactRef] = useAutoAnimate()
  const [addressRef] = useAutoAnimate()

  const handlePlaceChanged = (place: google.maps.places.PlaceResult | null) => {
    if (showUpsellModal.show) {
      return
    }

    // Short function to find each element
    const findItem: (props: {
      components: {
        long_name: string
        short_name: string
        types: string[]
      }[]
      item: string
      length: "short" | "long"
    }) => string | null = ({ components, item, length }) => {
      for (const component of components) {
        if (component.types.includes(item)) {
          if (length === "short") {
            return component.short_name
          }
          return component.long_name
        }
      }
      return null
    }

    if (place && place.address_components) {
      const components = place.address_components

      // When place is found, clear the fields to avoid conflicts when desired address has no data for one of the fields
      setValue("line2", "")
      setValue("city", "")
      setValue("state", "")
      setValue("country", "")
      setValue("postal_code", "")

      let line1 = ""

      const streetName = findItem({
        components,
        item: "route",
        length: "long",
      })
      if (streetName) {
        line1 = streetName
      }

      const streetNumber = findItem({
        components,
        item: "street_number",
        length: "long",
      })
      if (streetNumber) {
        line1 = streetNumber + " " + line1
      }

      setValue("line1", line1)
      clearErrors("line1")

      const locality = findItem({
        components,
        item: "locality",
        length: "long",
      })
      const city = findItem({
        components,
        item: "postal_town",
        length: "long",
      })
      const cityAlternative = findItem({
        components,
        item: "sublocality_level_1",
        length: "long",
      })
      const cityAlternative2 = findItem({
        components,
        item: "administrative_area_level_2",
        length: "long",
      })
      if (locality || city || cityAlternative || cityAlternative2) {
        setValue(
          "city",
          (locality || city || cityAlternative || cityAlternative2) as string
        )
        clearErrors("city")
      }

      const state = findItem({
        components,
        item: "administrative_area_level_1",
        length: "long",
      })
      if (state) {
        setValue("state", state)
        clearErrors("state")
      }

      const country = findItem({
        components,
        item: "country",
        length: "short",
      })
      if (country) {
        setValue("country", country)
        clearErrors("country")
      }

      const postalCode = findItem({
        components,
        item: "postal_code",
        length: "long",
      })
      if (postalCode) {
        setValue("postal_code", postalCode)
        clearErrors("postal_code")
      }
    }

    trackAddressFilled()
  }

  const [cartEmpty, setCartEmpty] = useState(false)

  useEffect(() => {
    if (!items || items?.length === 0) {
      setCartEmpty && setCartEmpty(true)
    } else {
      setCartEmpty(false)
    }

    const cuqi = Cookies.get("show-upsell-modal")
    if (cuqi && +atob(cuqi) <= 3 && +atob(cuqi) >= 1) {
      setShowUpsellModal({
        show: true,
        stage: +atob(cuqi) as UpsellStage,
        method: null,
      })
    } else {
      //If the user has an upsell open in store, and goes wandering
      //on other tabs, there's no obvious way to push him to order confirm page.
      //we could check if the last order on `localStorage` was made in the
      //last 10 minutes and push, but this is too much for an edge case for now.
      setShowUpsellModal({
        show: false,
        stage: null,
        method: null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    if (
      window &&
      !(document.body.style.overflow === "hidden") &&
      Cookies.get("show-upsell-modal")
    ) {
      document.body.style.overflow = "hidden"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUpsellModal])

  useEffect(
    () => {
      if (errors && Object.keys(errors).length > 0) {
        const values = Object.keys(errors)
        const errorToFocus = errors["email"]
          ? "email"
          : errors["phone"]
            ? "phone"
            : values[0]

        const el = document.getElementById(errorToFocus)
        const elTop = el?.getBoundingClientRect().top
        if (elTop) {
          window.scrollTo({
            top: document.documentElement.scrollTop + elTop - 300,
            behavior: "smooth",
          })
        }
        const timeout = setTimeout(() => {
          el?.focus()
        }, 500)
        return () => clearTimeout(timeout)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  )

  const buildForm = useCallback(() => {
    const form = getValues()

    return {
      email: form.email.toLowerCase(),
      phone: form.phone,
      country,
      currencyCode,
      shippingAddress: {
        firstName: form.first_name.trim(),
        lastName: form.last_name.trim(),
        addressLine1: form.line1.trim(),
        addressLine2: form.line2?.trim(),
        city: form.city.trim(),
        state: ["GB", "DE"].includes(form.country) ? "" : form.state.trim(),
        countryCode: form.country,
        postalCode: noPostCodeCountries
          .map((c) => c.code)
          .includes(form.postal_code)
          ? "0000"
          : form.postal_code.trim(),
      },
    } as Omit<Primer.HookProps, "onCheckoutComplete" | "onCheckoutFail">
  }, [country, currencyCode, getValues])

  const blurForm = () => {
    if (showUpsellModal.show) {
      return
    }
    const dirtyFieldNames = Object.keys(dirtyFields) as Array<
      keyof typeof dirtyFields
    >
    const validatableFields = dirtyFieldNames.filter(
      (fieldName) => fieldName !== "phone" && fieldName !== "email"
    )
    if (validatableFields.length > 0) {
      trigger(validatableFields)
    }
  }

  const blurEmail = () => {
    if (showUpsellModal.show) {
      return
    }
    if (!dirtyFields.email) return

    const email = getValues("email")

    trackActionBuffered("email_entered", { email })
    identifyUser({ email })
  }

  const blurPhone = () => {
    if (showUpsellModal.show) {
      return
    }
    if (!dirtyFields.phone) return

    const phoneNumber = getValues("phone")
    const phoneCheck = phone(phoneNumber, {
      country: phoneCountry,
      validateMobilePrefix: false,
    })

    if (phoneCheck.isValid) {
      clearErrors("phone")
    } else {
      setError("phone", {
        type: "manual",
        message: "Please enter a valid phone number",
      })
    }
    trackActionBuffered("phone_entered", { phone })
  }

  const changeCountry = () => {
    if (showUpsellModal.show) {
      return
    }
    const w = watch("country")

    if (!["US"].includes(w)) {
      setValue("state", "")
    }
    setCountry(w.toUpperCase())

    if (noPostCodeCountries.map((c) => c.code).includes(w)) {
      setValue("postal_code", "0000")
    }
  }

  const submit = async () => {
    // This is needed to display properly on confirmation
    const formData = buildForm()

    // Anti Fraud "simple protection"
    if (checkFraudEmail(formData.email)) {
      setError("root", {
        message: "Unexpected error, just like the spanish inquisition",
      })
    }

    setAddress({
      city: formData.shippingAddress.city,
      country: formData.shippingAddress.countryCode,
      email: formData.email,
      first_name: formData.shippingAddress.firstName,
      last_name: formData.shippingAddress.lastName,
      line1: formData.shippingAddress.addressLine1,
      line2: formData.shippingAddress.addressLine2,
      phone: formData.phone,
      postal_code: formData.shippingAddress.postalCode,
      state: formData.shippingAddress.postalCode,
    })

    trackAction("consent", {
      action: "accept",
      category: "promotional",
      valid_until: "unlimited",
      identification_type: "cookie",
      email: buildForm().email,
      identification: buildForm().email,
    })

    // ref: https://support.google.com/google-ads/answer/13258081?#Add_a_code_snippet&zippy=identify-and-define-your-enhanced-conversions-fields
    if (window.gtag) {
      window.gtag("set", "user_data", {
        email: formData.email,
        phone_number: formData.phone,
        address: {
          first_name: formData.shippingAddress.firstName,
          last_name: formData.shippingAddress.lastName,
          street: formData.shippingAddress.addressLine1,
          city: formData.shippingAddress.city,
          region: formData.shippingAddress.state,
          postal_code: formData.shippingAddress.postalCode,
          country: formData.shippingAddress.countryCode,
        },
      })
    }

    if (hasTextConsent) {
      trackAction("consent", {
        action: "accept",
        category: "promo-sms",
        valid_until: "unlimited",
        identification_type: "cookie",
        identification: buildForm().phone,
      })
    }

    setPhase("payment")
  }

  const renderStates = () => {
    const countryStateMap: any = {
      AU: AustraliaTerritories,
      CA: CanadaProvinces,
      GB: UkCountries,
      NZ: NewZealandRegions,
      US: UsStates,
    }

    const selectedCountry = watch("country")
    const states = countryStateMap[selectedCountry] || []

    return states.map((state: string) => (
      <option key={state} value={state}>
        {state}
      </option>
    ))
  }

  const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    reset({}, { keepValues: true })
    setPhase("shipping")
  }

  const trackAddressFilled = () => {
    if (shippingAddressTracked.current) return

    const formValues = getValues()
    trackAction("shipping_address_entered", formValues)
    shippingAddressTracked.current = true
  }

  return (
    <PaypalProviderWrapper>
      <main className="md:mt-3 bg-zinc-50">
        <div className="max-w-2xl px-4 pt-4 pb-24 mx-auto sm:px-6 sm:pt-8 lg:max-w-7xl lg:px-8">
          <form
            className="grid grid-cols-1 gap-y-4 sm:gap-x-4 md:grid-cols-2 md:gap-y-0 xl:gap-x-16"
            onBlur={blurForm}
          >
            <div className="flex flex-col order-2 md:order-first gap-y-4">
              <h2 className="text-3xl font-semibold text-center">
                Secure Checkout
              </h2>

              {cartEmpty ||
              isUpsellCart ||
              (!cart.validateChannel(process.env.NEXT_PUBLIC_CHANNEL_ID) &&
                !isUpsellCart) ? null : (
                <>
                  <Step phase={phase as string} />
                  {!expressDisabled && (
                    <div>
                      {coupon && coupon.min && totals.subTotal < coupon.min ? (
                        <div className="p-4 pb-2 my-4 bg-gray-200 rounded-sm">
                          <p>
                            Your coupon cannnot be used to complete this
                            purchase until you reach the minimum amount of{" "}
                            <Price value={coupon.min} /> + Shipping
                          </p>
                        </div>
                      ) : (
                        <ErrorBoundary>
                          <div
                            id="express-checkout"
                            className="px-4 py-2 mb-4 bg-gray-200 rounded-sm"
                          >
                            <h3 className="mb-4 font-semibold text-center text-gray-800">
                              Express Checkout
                            </h3>

                            {process.env.NEXT_PUBLIC_STAGE === "stg" &&
                            process.env.NEXT_PUBLIC_ENABLE_HYPERSWITCH ? (
                              <Hyperswitch
                                setShowUpsellModal={setShowUpsellModal}
                                setExpressClicked={setExpressClicked}
                              />
                            ) : (
                              <DirectExpressCheckout
                                grandTotal={totals.grandTotal}
                                currencyCode={currencyCode}
                                setShowUpsellModal={setShowUpsellModal}
                                setExpressClicked={setExpressClicked}
                              />
                            )}
                          </div>
                        </ErrorBoundary>
                      )}
                      <div className="mb-8 text-center">
                        <span className="text-xs">Secure Payment</span>
                        <div className="max-w-md bg-white rounded-full px-4 py-0.5 mx-auto shadow">
                          <TrustIcons />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div
                className={
                  cartEmpty ||
                  isUpsellCart ||
                  (!cart.validateChannel(process.env.NEXT_PUBLIC_CHANNEL_ID) &&
                    !isUpsellCart)
                    ? "pointer-events-none grayscale opacity-50 blur-sm"
                    : ""
                }
              >
                <CheckoutStep
                  isActive={true}
                  number={1}
                  name="Contact information"
                />

                {phase === "shipping" ? (
                  <div
                    ref={contactRef}
                    onClick={() => phase !== "shipping" && setPhase("shipping")}
                  >
                    <div onBlur={blurEmail}>
                      <EmailComplete
                        label="Email address"
                        email={watch("email")}
                        errors={errors}
                        disabled={
                          isSubmitSuccessful ||
                          (isSubmitting && Object.keys(errors).length <= 0)
                        }
                        control={control}
                      />
                    </div>
                    <div className="mt-2">
                      <div className="sm:col-span-2" onBlur={blurPhone}>
                        <PhoneInput
                          control={control}
                          errors={errors}
                          country={phoneCountry}
                          setCountry={setPhoneCountry}
                          disabled={
                            isSubmitSuccessful ||
                            (isSubmitting && Object.keys(errors).length <= 0)
                          }
                          className={classNames(styles.PhoneNumberInput)}
                        />
                      </div>
                      <div className="flex flex-row items-center justify-start gap-2 mt-1 text-sm font-medium text-zinc-700">
                        <input
                          aria-required
                          aria-label="agree"
                          id="agree"
                          name="agree"
                          type="checkbox"
                          checked={hasTextConsent}
                          onChange={() => setTextConsent(!hasTextConsent)}
                          className={styles.PhoneNumberConsentCheckbox}
                        />
                        <label htmlFor="agree">
                          Text me with news and offers.
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center w-full px-5 py-3 mt-4 text-sm bg-white border-0 rounded-xs ring-1 ring-gray-200 gap-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 text-zinc-500"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <div className="text-zinc-500">
                      <p className="font-semibold">{getValues("email")}</p>
                      <p>{getValues("phone")}</p>
                    </div>
                    <button
                      className="ml-auto underline grow-1"
                      onClick={handleEdit}
                    >
                      Edit
                    </button>
                  </div>
                )}
                <div className="mt-10">
                  <CheckoutStep
                    isActive={true}
                    number={2}
                    name="Shipping Information"
                  />
                  {phase === "shipping" ? (
                    <div
                      ref={addressRef}
                      onClick={() =>
                        phase !== "shipping" && setPhase("shipping")
                      }
                    >
                      <label className="block mt-4 text-sm font-medium text-zinc-700">
                        Shipping Address
                      </label>
                      <div className="grid grid-cols-1 mt-2 gap-y-2 sm:grid-cols-2 sm:gap-x-6">
                        <TextField
                          autocomplete="given-name"
                          errors={errors}
                          label="First name"
                          {...register("first_name", {
                            setValueAs: (value: string) => value.trim(),
                          })}
                          onChange={(event) => {
                            setValue("first_name", event.target.value)
                          }}
                          disabled={
                            isSubmitSuccessful ||
                            (isSubmitting && Object.keys(errors).length <= 0)
                          }
                        />
                        <TextField
                          autocomplete="family-name"
                          errors={errors}
                          label="Last name"
                          {...register("last_name", {
                            setValueAs: (value: string) => value.trim(),
                          })}
                          onChange={(event) => {
                            setValue("last_name", event.target.value)
                          }}
                          disabled={
                            isSubmitSuccessful ||
                            (isSubmitting && Object.keys(errors).length <= 0)
                          }
                        />

                        <div className="sm:col-span-2">
                          {process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ? (
                            <APIProvider
                              apiKey={
                                process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
                              }
                            >
                              <Autocomplete
                                onPlaceSelect={handlePlaceChanged}
                                render={(ref) => (
                                  <TextField
                                    errors={errors}
                                    name="line1"
                                    placeholder="Street address"
                                    label="Street address"
                                    autocomplete="address-line1"
                                    disabled={
                                      isSubmitSuccessful ||
                                      (isSubmitting &&
                                        Object.keys(errors).length <= 0)
                                    }
                                    ref={ref}
                                    value={watch("line1")}
                                    onChange={(event) =>
                                      setValue("line1", event.target.value)
                                    }
                                    onBlur={(event) =>
                                      setValue(
                                        "line1",
                                        event.target.value.trim()
                                      )
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </APIProvider>
                          ) : (
                            <TextField
                              autocomplete="address-level1"
                              errors={errors}
                              label="Street address"
                              {...register("line1", {
                                setValueAs: (value: string) => value.trim(),
                              })}
                              onChange={(event) =>
                                setValue("line1", event.target.value)
                              }
                              disabled={
                                isSubmitSuccessful ||
                                (isSubmitting &&
                                  Object.keys(errors).length <= 0)
                              }
                              fullWidth
                            />
                          )}
                        </div>
                        <TextField
                          autocomplete="address-line2"
                          errors={errors}
                          label={
                            country == "NZ"
                              ? "Suburb (optional)"
                              : "Apartment, suite, etc. (optional)"
                          }
                          {...register("line2")}
                          onChange={(event) =>
                            setValue("line2", event.target.value)
                          }
                          disabled={
                            isSubmitSuccessful ||
                            (isSubmitting && Object.keys(errors).length <= 0)
                          }
                          fullWidth
                        />
                        <TextField
                          autocomplete="address-level2"
                          errors={errors}
                          label={country == "AU" ? "Suburb" : "City"}
                          {...register("city", {
                            setValueAs: (value: string) => value.trim(),
                          })}
                          onChange={(event) =>
                            setValue("city", event.target.value)
                          }
                          disabled={
                            isSubmitSuccessful ||
                            (isSubmitting && Object.keys(errors).length <= 0)
                          }
                        />
                        <div onClick={(e) => e.preventDefault()}>
                          <div onChange={changeCountry}>
                            <select
                              aria-label="Select your Country"
                              id="country"
                              autoComplete="country"
                              {...register("country")}
                              className={classNames(
                                "block w-full border-gray-200 shadow-sm focus:border-zinc-500 focus:ring-zinc-500 sm:text-sm py-3",
                                isSubmitSuccessful ||
                                  (isSubmitting &&
                                    Object.keys(errors).length <= 0)
                                  ? "bg-gray-200"
                                  : ""
                              )}
                              disabled={
                                isSubmitSuccessful ||
                                (isSubmitting &&
                                  Object.keys(errors).length <= 0)
                              }
                            >
                              <option value=""></option>
                              {CountriesWithPriorityOnTop.map((country) => (
                                <option key={country.code} value={country.code}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {errors &&
                              errors["country"] &&
                              errors["country"] && (
                                <p className="text-sm text-red-500">
                                  {errors["country"]?.message}
                                </p>
                              )}
                          </div>
                        </div>
                        {!["GB", "DE"].includes(country) && (
                          <div>
                            <div onClick={(e) => e.preventDefault()}>
                              {watch &&
                              ["AU", "CA", "GB", "NZ", "US"].includes(
                                watch("country")
                              ) ? (
                                <select
                                  aria-label="Select your State"
                                  autoComplete="address-level1"
                                  className={classNames(
                                    "block w-full border-gray-200 shadow-sm focus:border-zinc-500 focus:ring-zinc-500 sm:text-sm py-3",
                                    isSubmitSuccessful ||
                                      (isSubmitting &&
                                        Object.keys(errors).length <= 0)
                                      ? "bg-gray-200"
                                      : ""
                                  )}
                                  disabled={
                                    isSubmitSuccessful ||
                                    (isSubmitting &&
                                      Object.keys(errors).length <= 0)
                                  }
                                  {...register("state")}
                                >
                                  <option value=""></option>
                                  {renderStates()}
                                </select>
                              ) : (
                                <input
                                  aria-required
                                  aria-label="Select your Region"
                                  autoComplete="address-level1"
                                  type="text"
                                  id="region"
                                  className={classNames(
                                    "block w-full border-gray-200 shadow-sm focus:border-zinc-500 focus:ring-zinc-500 sm:text-sm placeholder-gray-300 py-3",
                                    isSubmitting &&
                                      Object.keys(errors).length <= 0
                                      ? "bg-gray-200"
                                      : "",
                                    isSubmitSuccessful ||
                                      (isSubmitting &&
                                        Object.keys(errors).length <= 0)
                                      ? "bg-gray-200"
                                      : ""
                                  )}
                                  placeholder={
                                    country === "US"
                                      ? "State"
                                      : country === "CA"
                                        ? "Province"
                                        : country === "AU"
                                          ? "State / Territory"
                                          : country === "NZ"
                                            ? "Region"
                                            : "State / Province"
                                  }
                                  disabled={
                                    isSubmitSuccessful ||
                                    (isSubmitting &&
                                      Object.keys(errors).length <= 0)
                                  }
                                  {...register("state")}
                                />
                              )}
                            </div>
                            {errors && errors["state"] && (
                              <p className="text-sm text-red-500">
                                {errors["state"]?.message}
                              </p>
                            )}
                          </div>
                        )}
                        {!noPostCodeCountries
                          .map((c) => c.code)
                          .includes(country) && (
                          <div>
                            <TextField
                              autocomplete="postal-code"
                              errors={errors}
                              label={country === "US" ? "ZIP" : "Postal code"}
                              disabled={
                                isSubmitSuccessful ||
                                (isSubmitting &&
                                  Object.keys(errors).length <= 0)
                              }
                              {...register("postal_code")}
                              onChange={(event) =>
                                setValue("postal_code", event.target.value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center w-full px-5 py-3 mt-4 text-sm bg-white border-0 rounded-xs ring-1 ring-gray-200 gap-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 text-zinc-500"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                      <div className="text-zinc-600">
                        <p className="font-semibold">
                          {getValues("first_name")} {getValues("last_name")}
                        </p>
                        <p>
                          {getValues("line1")}, {getValues("line2")}
                        </p>
                        <p>
                          {getValues("city")}, {getValues("state")}
                        </p>
                        {!noPostCodeCountries
                          .map((c) => c.code)
                          .includes(getValues("country")) && (
                          <p className="font-medium">
                            {getValues("postal_code")}
                          </p>
                        )}
                      </div>
                      <button
                        className="ml-auto underline grow-1"
                        onClick={(e) => handleEdit(e)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>

                {isUSA() && <Tipping enabled={phase === "shipping"} />}

                {phase === "shipping" && (
                  <div className="mt-8 border-t border-zinc-200">
                    <button
                      id="proceed-to-payment-action-button"
                      className="w-full px-4 py-3 mt-6 text-base font-medium text-white border border-transparent rounded shadow-sm bg-emerald-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2 focus:ring-offset-zinc-50"
                      disabled={isSubmitting && Object.keys(errors).length <= 0}
                      type="button"
                      onClick={async (e) => {
                        // Native submit is problematic on mobile, so we do it manually.
                        // This must happen before manually setting errors.
                        const formValid = await trigger()

                        // Check for coupon
                        if (
                          coupon &&
                          coupon.min &&
                          totals.subTotal < coupon.min
                        ) {
                          // TODO: Make this neat
                          window.alert(
                            `Your coupon requires a minimum spend of ${currencyCode} ${(
                              coupon.min / 100
                            ).toFixed(
                              2
                            )} - either add more items to your cart or remove the coupon to continue.`
                          )
                          return
                        }

                        const isValid = phone(getValues("phone"), {
                          country: phoneCountry,
                          validateMobilePrefix: false,
                        }).isValid

                        if (formValid && isValid) {
                          handleSubmit(submit)()
                          return
                        }

                        if (!isValid) {
                          setError("phone", {
                            type: "manual",
                            message: "Please enter a valid phone number",
                          })
                        }

                        trackAction("form_validation_failed")
                      }}
                    >
                      {isSubmitting && Object.keys(errors).length <= 0
                        ? "Validating..."
                        : totals.grandTotal === 0
                          ? "Proceed"
                          : "Proceed to Payment"}
                    </button>
                  </div>
                )}
                {/* Payment */}
                {phase === "payment" && (
                  <div className="mt-8">
                    <div className="mb-6">
                      <CheckoutStep
                        isActive={phase === "payment" ? true : false}
                        number={3}
                        name={"Payment"}
                      />
                    </div>
                    {totals.grandTotal === 0 ? (
                      <div className="mt-8 border-t border-zinc-200">
                        <button
                          id="create-free-order-action-button"
                          className="w-full px-4 py-3 mt-6 text-base font-medium text-white border border-transparent rounded shadow-sm bg-zinc-800 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2 focus:ring-offset-zinc-50"
                          disabled={
                            submitFree && Object.keys(errors).length <= 0
                          }
                          type="button"
                          onClick={async () => {
                            setSubmitFree(true)

                            const form = buildForm()
                            const body = getFreeOrderSessionBody({
                              cart,
                              bonusItems,
                              currency: currencyCode!,
                              email: form.email,
                              locale: country,
                              phone: form.phone,
                              shipping: form.shippingAddress,
                            })

                            try {
                              await fetchApi({
                                endpoint: "/api/fo/",
                                method: "POST",
                                data: body,
                              })
                            } catch (error) {
                              console.error(error)
                              return
                            } finally {
                              // All conversion scripts tracking

                              trackPurchase({
                                cart,
                                currencyCode,
                              })

                              cart.convertToOrder({
                                currency: currencyCode,
                                method: "Gift Card",
                                cb: addOrder,
                              })

                              push("/order/redirect/none/")
                            }
                          }}
                        >
                          Submit Order
                        </button>
                      </div>
                    ) : (
                      <Payment
                        currencyCode={currencyCode!}
                        email={buildForm().email}
                        phone={buildForm().phone}
                        shippingAddress={buildForm().shippingAddress}
                        onSuccess={(params) => {
                          Cookies.set("show-upsell-modal", btoa("1"), {
                            expires: new Date(
                              new Date().getTime() + 10 * 60 * 1000
                            ),
                          })
                          setShowUpsellModal({
                            show: true,
                            stage: 1,
                            method: (params.method as any) ?? "stripe",
                          })
                        }}
                      />
                    )}
                  </div>
                )}
                <p className="my-2 text-xs text-center text-gray-700">
                  By confirming payment you are agreeing to all our terms and
                  conditions.
                </p>
              </div>
              <div className="mt-4 text-center">
                {trustPilotSlider && <div>{trustPilotSlider}</div>}
              </div>
            </div>
            <div className="order-first md:order-last">
              {cartEmpty ||
              isUpsellCart ||
              (!cart.validateChannel(process.env.NEXT_PUBLIC_CHANNEL_ID) &&
                !isUpsellCart) ? (
                <>
                  {emptyCartComponent && (cartEmpty || isUpsellCart) ? (
                    <>{emptyCartComponent}</>
                  ) : (
                    <EmptyCartFallback />
                  )}
                  <div className="w-full mt-10 text-center ">
                    <Link href="/" className="">
                      &larr; Go back to{" "}
                      <span className="font-bold">Baerskin Tactical Store</span>{" "}
                    </Link>
                  </div>
                </>
              ) : (
                <OrderSummary setPhase={setPhase} />
              )}
            </div>
            <div className="order-last mt-8 md:hidden">
              <FaqCheckout />
            </div>
          </form>
        </div>

        {items && (
          <MemoTrackScript
            name="begin_checkout"
            input={{
              currency: currencyCode,
              discount: clearDecimals((totals.discount ?? 0) / 100, 2),
              value: clearDecimals(totals.grandTotal / 100, 2),
              items: items.map((item, i) => ({
                item_id: item?.variantId ?? (item?.productId as string),
                item_name: item.productName,
                item_variant: item.variantName || null,
                position: i + 1,
                price: clearDecimals((item.price ?? 0) / 100, 2),
              })),
              shipping: clearDecimals(totals.shipping / 100, 2),
              tax: clearDecimals(totals.tax / 100, 2),
              gateway: "primer",
            }}
          />
        )}

        <Suspense>
          <RegionSelector hidden={true} />
        </Suspense>

        {showUpsellModal.show &&
          createPortal(
            <UpsellModalClient modalRef={modalRef} open={true}>
              <UpsellPopupClient
                channel={channel}
                method={showUpsellModal.method}
                modalRef={modalRef}
                prefetchedData={prefetchedUpsellData}
              />
            </UpsellModalClient>,
            document.body
          )}
      </main>
    </PaypalProviderWrapper>
  )
}
