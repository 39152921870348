import { useCheckpoint } from "@local/utils/src/useCheckpoint"
import Link from "next/link"
import { classNames } from "@local/utils"

interface Props {
  checkpoint?: boolean
  close?: () => void
  goto?: string
  text?: string
  noOpacity?: boolean
  lightMode?: boolean
}

export const LoadingOverlay = ({
  close,
  checkpoint,
  text,
  goto,
  noOpacity,
  lightMode,
}: Props) => {
  useCheckpoint(checkpoint)
  return (
    <div
      className={classNames(
        "fixed left-0 top-0 flex h-full w-full items-center justify-center z-[2000]",
        noOpacity ? "" : "bg-opacity-75",
        lightMode ? "bg-gray-100" : "bg-black"
      )}
    >
      <div
        className={classNames(
          "p-8 ",
          lightMode ? "text-black" : "text-white shadow-lg"
        )}
      >
        <h2 className="mb-4 text-2xl font-semibold text-center">
          {text || "Your order is processing, do not close this window"}
        </h2>
        {close && (
          <div className="flex justify-center">
            <button className="underline" onClick={close}>
              Click here to cancel
            </button>
          </div>
        )}
        {goto && (
          <div className="flex justify-center">
            <Link className="underline" href={goto}>
              If you are not redirected, click here to continue
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingOverlay
