import { type Dispatch, type SetStateAction, useState } from "react"
import { useCart } from "@local/cart/src/useCart"
import { LoadingSpinner } from "./LoadingSpinner"
import { useI10n } from "@local/i10n"

interface Props {
  setPhase: Dispatch<SetStateAction<"shipping" | "payment">>
}

export const CouponHandler = ({ setPhase }: Props) => {
  const [codeInput, setCodeInput] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const { addCoupon, removeCoupon, coupon } = useCart()
  const { currencyCode } = useI10n()

  const sendCoupon = async () => {
    try {
      setIsLoading(true)
      if (codeInput.length <= 0) {
        setError("Coupon code is required")
        return
      }
      setError("")
      const req = await addCoupon({
        code: codeInput,
        currency: currencyCode,
      })
      if (req.error === "COUPON_INVALID") {
        setError("Coupon code is invalid")
      } else if (req.error === "COUPON_MINIMUM") {
        setError("This coupon has a minimum purchase amount to be valid")
      } else if (req.error === "COUPON_POLITE") {
        setError("Preeeeeety please???")
      }
      setPhase("shipping")
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setError("Coupon code is invalid")
      setIsLoading(false)
    }
  }

  return coupon ? (
    <dt className="text-sm">
      <div className="flex flex-row">
        Coupon Code {coupon.code || <LoadingSpinner />}
      </div>
      <button
        className="text-xs text-blue-500 underline pointer"
        onClick={(e) => {
          e.preventDefault()
          // @ts-ignore
          if (typeof window !== "undefined") {
            // @ts-ignore
            const confirm = window.confirm(
              "Are you sure you want to remove your discount?"
            )
            if (confirm) {
              removeCoupon()
            }
          }
        }}
      >
        remove
      </button>
    </dt>
  ) : (
    <>
      <details
        className="w-full cursor-pointer"
        id="add-a-discount-code-details-disclosure"
      >
        <summary className="text-sm font-semibold">Add a Discount code</summary>
        <div className="flex gap-2 pt-3">
          <input
            aria-required
            aria-label="Coupon code"
            type="text"
            className="w-full fld"
            value={codeInput}
            onChange={(e) => setCodeInput(e.target.value)}
            id="add-a-discount-code-text-input"
          />
          <button
            className="w-32 p-3 text-white bg-zinc-600"
            onClick={(e) => {
              e.preventDefault()
              sendCoupon()
            }}
            disabled={isLoading}
            id="add-a-discount-code-button-add"
          >
            {isLoading ? "Adding" : "Add"}
          </button>
        </div>
        {error ? <p className="pt-3 text-red-400">{error}</p> : null}
      </details>
    </>
  )
}

export const GiftCardHandler = ({ setPhase }: Props) => {
  const [codeInput, setCodeInput] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState("")
  const { addGiftCard, removeGiftCard, giftCard } = useCart()

  const sendCoupon = async () => {
    try {
      setIsLoading(true)
      if (codeInput.length <= 0) {
        setError("Gift card code is required")
        return
      }
      setError("")
      const req = await addGiftCard(codeInput)
      if (req.error === "GIFTCARD_INVALID") {
        setError("Gift card is invalid")
      } else {
        setPhase("shipping")
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setError("Gift card is invalid")
      setIsLoading(false)
    }
  }

  return giftCard ? (
    <dt className="text-sm">
      <div className="flex flex-row">
        Gift Card {giftCard.code || <LoadingSpinner />}
      </div>
      <button
        className="text-xs text-blue-500 underline pointer"
        onClick={(e) => {
          e.preventDefault()
          removeGiftCard()
        }}
      >
        remove
      </button>
    </dt>
  ) : (
    <>
      <details className="w-full cursor-pointer">
        <summary className="text-sm font-semibold">
          Add a Gift Card code
        </summary>
        <div className="flex gap-2 pt-3">
          <input
            aria-required
            aria-label="Coupon code"
            type="text"
            className="w-full fld"
            value={codeInput}
            onChange={(e) => setCodeInput(e.target.value)}
          />
          <button
            className="w-32 p-3 text-white bg-zinc-600"
            onClick={(e) => {
              e.preventDefault()
              sendCoupon()
            }}
            disabled={isLoading}
          >
            {isLoading ? "Adding" : "Add"}
          </button>
        </div>
        {error ? <p className="pt-3 text-red-400">{error}</p> : null}
      </details>
    </>
  )
}
