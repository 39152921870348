import { useEffect } from "react"

/**
 * useCheckpoint React custom hook
 * This creates an alert to avoid the user from leaving the page in case things can be lost.
 *
 * @param active - Enables/Disables externally the checkpoint
 *
 * @example ```ts
 * useCheckpoint(true);
 * ```
 */
export const useCheckpoint = (active?: Boolean) => {
  useEffect(() => {
    if (!active) {
      return
    }

    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault()

      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [active])
}
