export const UsBasesZipCodes = [
  ["00000", "00499"],
  ["00600", "00999"],
  ["09001", "09006"],
  ["09007", "09009"],
  ["09010", "09014"],
  ["09015", "09018"],
  ["09019", "09021"],
  ["09027", "09028"],
  ["09033", "09034"],
  ["09043", "09044"],
  ["09045", "09046"],
  ["09048", "09049"],
  ["09052", "09054"],
  ["09059", "09060"],
  ["09066", "09069"],
  ["09078", "09079"],
  ["09089", "09090"],
  ["09093", "09096"],
  ["09103", "09104"],
  ["09106", "09107"],
  ["09111", "09112"],
  ["09113", "09114"],
  ["09122", "09123"],
  ["09124", "09126"],
  ["09127", "09128"],
  ["09130", "09131"],
  ["09135", "09136"],
  ["09137", "09138"],
  ["09139", "09140"],
  ["09141", "09142"],
  ["09153", "09154"],
  ["09159", "09160"],
  ["09171", "09173"],
  ["09176", "09177"],
  ["09179", "09180"],
  ["09185", "09186"],
  ["09202", "09204"],
  ["09210", "09211"],
  ["09212", "09214"],
  ["09226", "09227"],
  ["09249", "09250"],
  ["09260", "09261"],
  ["09262", "09265"],
  ["09300", "09302"],
  ["09303", "09317"],
  ["09318", "09321"],
  ["09329", "09330"],
  ["09332", "09333"],
  ["09336", "09337"],
  ["09339", "09340"],
  ["09342", "09343"],
  ["09346", "09348"],
  ["09350", "09352"],
  ["09353", "09357"],
  ["09362", "09363"],
  ["09364", "09366"],
  ["09377", "09378"],
  ["09380", "09381"],
  ["09402", "09403"],
  ["09420", "09421"],
  ["09446", "09447"],
  ["09453", "09454"],
  ["09458", "09459"],
  ["09460", "09461"],
  ["09462", "09464"],
  ["09467", "09470"],
  ["09493", "09494"],
  ["09496", "09498"],
  ["09500", "09511"],
  ["09512", "09513"],
  ["09516", "09517"],
  ["09519", "09520"],
  ["09521", "09524"],
  ["09531", "09534"],
  ["09540", "09542"],
  ["09543", "09545"],
  ["09549", "09550"],
  ["09553", "09554"],
  ["09555", "09556"],
  ["09563", "09570"],
  ["09572", "09579"],
  ["09580", "09583"],
  ["09585", "09596"],
  ["09598", "09600"],
  ["09601", "09610"],
  ["09612", "09614"],
  ["09616", "09618"],
  ["09619", "09627"],
  ["09629", "09631"],
  ["09632", "09633"],
  ["09635", "09636"],
  ["09641", "09643"],
  ["09644", "09645"],
  ["09646", "09649"],
  ["09700", "09712"],
  ["09713", "09720"],
  ["09721", "09739"],
  ["09740", "09745"],
  ["09747", "09756"],
  ["09757", "09762"],
  ["09768", "09769"],
  ["09776", "09777"],
  ["09779", "09780"],
  ["09788", "09789"],
  ["09799", "09806"],
  ["09807", "09818"],
  ["09819", "09839"],
  ["09840", "09842"],
  ["09843", "09848"],
  ["09850", "09865"],
  ["09866", "09870"],
  ["09872", "09875"],
  ["09879", "09880"],
  ["09889", "09890"],
  ["09891", "09892"],
  ["09894", "09895"],
  ["09897", "09898"],
  ["09900", "09999"],
  ["21300", "21399"],
  ["26900", "26999"],
  ["34000", "34002"],
  ["34003", "34004"],
  ["34006", "34008"],
  ["34010", "34011"],
  ["34019", "34025"],
  ["34029", "34039"],
  ["34040", "34042"],
  ["34043", "34044"],
  ["34049", "34055"],
  ["34057", "34058"],
  ["34059", "34060"],
  ["34077", "34078"],
  ["34079", "34095"],
  ["34300", "34399"],
  ["34500", "34599"],
  ["34800", "34899"],
  ["35300", "35399"],
  ["41900", "41999"],
  ["42800", "42999"],
  ["51700", "51999"],
  ["52900", "52999"],
  ["53300", "53399"],
  ["53600", "53699"],
  ["55200", "55299"],
  ["56800", "56999"],
  ["57800", "57999"],
  ["58900", "58999"],
  ["62100", "62199"],
  ["63200", "63299"],
  ["64200", "64399"],
  ["65900", "65999"],
  ["66300", "66399"],
  ["68200", "68299"],
  ["69400", "69999"],
  ["70200", "70299"],
  ["70900", "70999"],
  ["71500", "71599"],
  ["73200", "73299"],
  ["74200", "74299"],
  ["77100", "77199"],
  ["81700", "81999"],
  ["83900", "83999"],
  ["84800", "84999"],
  ["85400", "85499"],
  ["85800", "85899"],
  ["86100", "86299"],
  ["86600", "86699"],
  ["86700", "86999"],
  ["87200", "87299"],
  ["87600", "87699"],
  ["88600", "88899"],
  ["89200", "89299"],
  ["89600", "89699"],
  ["89900", "89999"],
  ["90900", "90999"],
  ["92900", "92999"],
  ["96200", "96210"],
  ["96211", "96214"],
  ["96217", "96218"],
  ["96223", "96224"],
  ["96250", "96251"],
  ["96256", "96258"],
  ["96259", "96260"],
  ["96261", "96262"],
  ["96263", "96264"],
  ["96265", "96267"],
  ["96268", "96269"],
  ["96270", "96271"],
  ["96272", "96273"],
  ["96274", "96276"],
  ["96277", "96278"],
  ["96282", "96284"],
  ["96296", "96297"],
  ["96300", "96301"],
  ["96302", "96303"],
  ["96305", "96306"],
  ["96308", "96310"],
  ["96318", "96319"],
  ["96320", "96323"],
  ["96325", "96326"],
  ["96327", "96328"],
  ["96329", "96331"],
  ["96335", "96339"],
  ["96342", "96343"],
  ["96345", "96347"],
  ["96348", "96351"],
  ["96361", "96362"],
  ["96364", "96365"],
  ["96366", "96368"],
  ["96369", "96370"],
  ["96371", "96380"],
  ["96381", "96382"],
  ["96383", "96389"],
  ["96400", "96401"],
  ["96500", "96501"],
  ["96503", "96505"],
  ["96506", "96507"],
  ["96509", "96511"],
  ["96514", "96517"],
  ["96519", "96521"],
  ["96529", "96532"],
  ["96533", "96535"],
  ["96536", "96537"],
  ["96539", "96540"],
  ["96541", "96543"],
  ["96545", "96546"],
  ["96547", "96555"],
  ["96556", "96557"],
  ["96561", "96562"],
  ["96576", "96578"],
  ["96594", "96595"],
  ["96597", "96599"],
  ["96600", "96613"],
  ["96614", "96617"],
  ["96618", "96620"],
  ["96627", "96629"],
  ["96631", "96633"],
  ["96640", "96645"],
  ["96648", "96650"],
  ["96656", "96657"],
  ["96659", "96675"],
  ["96676", "96679"],
  ["96680", "96683"],
  ["96685", "96686"],
  ["96690", "96696"],
  ["96697", "96698"],
  ["96700", "96799"],
  ["96800", "96899"],
  ["96900", "96999"],
  ["98700", "98799"],
  ["99500", "99999"],
]

const knownFraudZipCodes = [
  [
    "33166",
    "33166", // Miami
  ],
  [
    "33175",
    "33178", // Miami
  ],
  [
    "10468", // Bronks carder
  ],
]

const blockedZipCodes = [...UsBasesZipCodes, ...knownFraudZipCodes]

export const validateUsZip = (zipCode: string) => {
  const zipNumber = parseInt(zipCode, 10)
  let valid = true

  for (const item of blockedZipCodes) {
    if (zipNumber >= parseInt(item[0]) && zipNumber <= parseInt(item[1], 10)) {
      valid = false
      break
    }
  }

  return valid
}
