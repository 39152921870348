import { BonusCartItem, CartState } from "@local/cart/src/types"
import { getMetadata } from "@local/tracking/src/metadata"
import { Klarna as TKlarna } from "../types"
import Cookies from "js-cookie"

export interface KlarnaSessionOrderPayloadProps {
  cart: CartState
  bonusItems: BonusCartItem[]
  currencyCode: string
  locale: string
  upsell?: {
    slot: number
    id: string
    orderId: string
  }
  country: string
  slug?: string | null
}

export const getKlarnaSessionOrderPayload = ({
  cart,
  bonusItems,
  currencyCode,
  locale,
  upsell,
  country,
  slug,
}: KlarnaSessionOrderPayloadProps) => {
  const { totals, items, coupon, giftCard } = cart
  const cartId = cart.id ?? Cookies.get("div-cart")
  const cartUid = cart.uid ?? Cookies.get("div-cart-short")
  const couponCode = coupon?.code
  const giftCardCode = giftCard?.code
  const discountAmount = totals?.discount
    ? Math.round(totals.discount)
    : undefined
  const giftcardAmount = totals?.giftCard
    ? Math.round(totals.giftCard)
    : undefined
  const shippingCost = totals?.shipping
  const tip = totals.tip

  // Mount the order lines from the cart items
  const order_lines: TKlarna.OrderLine[] = items.map((item) => ({
    type: item.type === "giftcard" ? "digital" : "physical",
    reference: item.variantName || "",
    name: item.productName,
    quantity: item.quantity,
    unit_price: item.price!,
    total_amount: Math.round(item.price! * item.quantity),
    total_tax_amount: 0,
    tax_rate: 0,
    merchant_data: item.variantId
      ? `${item.variantId}_${item.productId}`
      : item.productId,
    image_url: item.image || undefined,
  }))

  // Add coupon code as discount item
  if (discountAmount) {
    order_lines.push({
      type: "discount",
      reference: couponCode,
      name: "Discount Coupon",
      quantity: 1,
      unit_price: -discountAmount,
      total_amount: -discountAmount,
      total_tax_amount: 0,
      tax_rate: 0,
    })
  }

  // Add gift card as discount item
  if (giftcardAmount) {
    order_lines.push({
      type: "gift_card",
      reference: giftCardCode,
      name: "Gift Card",
      quantity: 1,
      unit_price: -giftcardAmount,
      total_amount: -giftcardAmount,
      total_tax_amount: 0,
      tax_rate: 0,
    })
  }

  // Add shipping cost
  if (totals.shipping) {
    order_lines.push({
      type: "shipping_fee",
      reference: "shipping",
      name: "Shipping",
      quantity: 1,
      unit_price: shippingCost!,
      total_amount: shippingCost!,
      total_tax_amount: 0,
      tax_rate: 0,
    })
  }

  // Add extra costs
  if (totals.tip) {
    order_lines.push({
      type: "surcharge",
      reference: "tip",
      name: "Tip",
      quantity: 1,
      unit_price: tip!,
      total_amount: tip!,
      total_tax_amount: 0,
      tax_rate: 0,
    })
  }

  // Metadata stuff
  const order = items.map((item) => ({
    amount: Math.round(item.price!),
    bundleItems: item.bundleItems?.map((bundleItem) => bundleItem),
    description: item.variantName || item.productName,
    giftWrap: item.giftWrap,
    itemId: item.variantId
      ? `${item.variantId}_${item.productId}`
      : item.productId,
    name: item.productName,
    prices: item.prices,
    price: Math.round(item.price!),
    preOrder: item.preOrder,
    productId: item.productId,
    productName: item.productName,
    quantity: item.quantity,
    sku: item.sku,
    type: item.type,
    variantId:
      item.bundleItems && item.bundleItems.length > 0
        ? undefined
        : item.variantId,
    variantName:
      item.bundleItems && item.bundleItems.length > 0
        ? undefined
        : item.variantName,
  }))

  if (bonusItems?.length) {
    bonusItems
      .filter((bonusItem) => bonusItem.giftAccepted)
      .forEach((bonusItem) => {
        if (bonusItem.cartItem) {
          const item = bonusItem.cartItem

          order.push({
            amount: 0,
            bundleItems: undefined,
            description: item.variantName || item.productName,
            giftWrap: null,
            itemId: item.variantId
              ? `${item.variantId}_${item.productId}`
              : item.productId,
            name: item.productName,
            quantity: item.quantity,
            price: 0,
            prices: [],
            preOrder: null,
            sku: item.sku,
            type: "standard",
            variantName: item.variantName,
            variantId: item.variantId,
            productName: item.productName,
            productId: item.productId,
          })
        }
      })
  }

  const upsellMetadata = upsell
    ? {
        upsell: upsell.orderId,
        upsell_slot: upsell.slot,
        upsell_id: upsell.id,
      }
    : {}

  const metadata = {
    ...getMetadata(),
    // Fallback for multi setup
    channel_id:
      slug ?? window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string),
    currency: currencyCode,
    locale,
    order,
    shippingCost,
    discountAmount,
    couponCode,
    giftCard: giftCardCode,
    cartId,
    cartUid,
    tip,
    ...upsellMetadata,
  }

  // Mount full Klarna body
  const body: TKlarna.CreatePaymentsSessionRequest = {
    intent: "buy",
    purchase_country: country,
    purchase_currency: currencyCode,
    locale,
    order_amount: Math.round(totals.grandTotal),
    order_tax_amount: Math.round(totals.tax || 0),
    order_lines,
    merchant_data: JSON.stringify(metadata),
    merchant_reference1: cartId!,
    merchant_reference2: cartUid!,
  }

  return body
}
