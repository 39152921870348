import Image from "next/image"
import { Price } from "@local/i10n"
import { OptionsSelectorSingleItemState } from "../types"
import { cva } from "class-variance-authority"
import type { Payload } from "@local/payload-client/src/types"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
import { useCarouselDataParser } from "@local/utils/src/useCarouselDataParser"
import { classNames } from "@local/utils"

interface Props {
  product: Payload.Product
  state?: OptionsSelectorSingleItemState | null
  changeProduct: (id: string, index?: number) => void
  price: number
  optionIndex: number
}

export const StylePickerSingleServer = ({
  changeProduct,
  product,
  state,
  price,
  optionIndex,
}: Props) => {
  const { coverOptionImage } = useCarouselDataParser(product)

  if (state === OptionsSelectorSingleItemState.disabled) {
    return null
  }

  const styles = cva(
    "text-center border border-gray-300 hover:border-gray-400 rounded-lg border-[2px] !list-none bg-gray-100",
    {
      variants: {
        state: {
          disabled: ["grayscale", "opacity-50", "hover:!border-gray-300"],
          selected: [
            "!border-blue-400",
            "hover:!border-blue-500",
            "!border-[2.4px]",
          ],
          default: ["border-gray-300", "hover:border-gray-400"],
        },
      },
      defaultVariants: {
        state: "default",
      },
    }
  )

  const imageUrl = coverOptionImage?.url ?? product.images[0].image.url

  const productName = product.short ?? product.name.substring(0, 21) + "..."

  return (
    <li
      className={styles(!product.hasStock ? { state: "disabled" } : { state })}
    >
      <button
        onClick={() => {
          if (product.hasStock) {
            changeProduct(product.id, optionIndex)
            optionIndex
          }
        }}
        className={classNames(
          "box-content w-11/12",
          product.hasStock ? "" : "cursor-default"
        )}
      >
        <div className="w-full m-auto mt-1 aspect-square">
          <Image
            src={imageUrl}
            width={product.images[0]?.image.width}
            height={product.images[0]?.image.height}
            alt={product.name}
            className="rounded-lg aspect-square"
            loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined}
          />
        </div>
        <div className="mt-2.5 !leading-none text-xs">
          <p>{productName.length > 1 ? productName : product.name}</p>
          <p className="pt-1 pb-1 text-xxs">
            <Price value={price} />
          </p>
        </div>
      </button>
    </li>
  )
}
