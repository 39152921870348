import type {
  CartOption,
  ProductOption,
} from "@local/product-option-manager/types"
import { OptionsSelectorSingleItemState } from "../types"
import { ColorPickerSingleClient } from "./ColorPickerSingle"
import { GenericOptionsPickerSingleClient } from "./GenericOptionPickerSingle"
import type { Payload } from "@local/payload-client/src/types"
import { useId } from "react"
import { SizeChartsClient } from "./SizeCharts"

interface OptionProps {
  index: number
  product: Payload.Product | Payload.Bucket
  options: ProductOption[]
  selectedCartOptions: CartOption[]
  handleChange: (
    optionName: string,
    valueName: string,
    localizedName: string
  ) => void
  handleUpperOptionChange: (variant: Payload.ProductVariant) => void
  checkVariantStock: (productId: string, variantId: string) => boolean
}

export const OptionsPickerServer = ({
  index,
  product,
  options,
  handleUpperOptionChange,
  selectedCartOptions,
  handleChange,
  checkVariantStock,
}: OptionProps) => {
  const optionIndex = index
  const option = options?.[optionIndex]
  const selectedCartOption = selectedCartOptions.find(
    (o) => o.optionId === option.id
  )
  const uid = useId()
  const isColor = option?.name?.toLowerCase() === "color"
  const isSize = option?.name?.toLowerCase() === "size"

  const variants = (product as Payload.Product).variants

  const getUpperSelectedOptions = () => {
    const selectedOptions: {
      option: string
      value: string
      localizedName: string
    }[] = []
    if (optionIndex > 0) {
      for (let i = 0; i < optionIndex; i++) {
        const option = options[i]
        const selectedOption = selectedCartOptions.find(
          (o) => o.optionName === option?.name
        )
        if (selectedOption) {
          selectedOptions.push({
            option: option?.name,
            value: selectedOption.valueName,
            localizedName: selectedOption.localizedName,
          })
        }
      }
    }
    return selectedOptions
  }

  const getUnderSelectedOptions = () => {
    const selectedOptions: {
      option: string
      value: string
      localizedName: string
    }[] = []
    if (optionIndex < options.length - 1) {
      for (let i = optionIndex + 1; i <= options.length - 1; i++) {
        const option = options[i]
        const selectedOption = selectedCartOptions.find(
          (o) => o.optionName === option?.name
        )
        if (selectedOption) {
          selectedOptions.push({
            option: option?.name,
            value: selectedOption.valueName,
            localizedName: selectedOption.localizedName,
          })
        }
      }
    }
    return selectedOptions
  }

  const hasAnyStockAvailable = (valueName: string, localizedName: string) => {
    const selectedOptions = getUpperSelectedOptions()
    const optionsToCheck = [
      ...selectedOptions,
      { option: option?.name, value: valueName, localizedName },
    ]

    const optionVariants = variants?.filter((variant) =>
      optionsToCheck.every((option) =>
        variant.options.some(
          (o) => o.option === option?.option && o.value === option?.value
        )
      )
    )

    return optionVariants?.some((variant) =>
      checkVariantStock(product.id, variant.id)
    )
  }

  const handleClick = (valueName: string, localizedName: string) => {
    if (optionIndex < options.length - 1) {
      const underSelectedOptions = getUnderSelectedOptions()
      const upperSelectedOptions = getUpperSelectedOptions()

      // First check for all selected values
      const optionsToCheck = [
        ...upperSelectedOptions,
        { option: option?.name, value: valueName, localizedName },
        ...underSelectedOptions,
      ]
      const optionVariants = variants?.filter((variant) =>
        optionsToCheck.every((option) =>
          variant.options.some(
            (o) => o.option === option?.option && o.value === option?.value
          )
        )
      )

      let variantInStock = optionVariants?.find((variant) =>
        checkVariantStock(product.id, variant.id)
      )

      // Second check for only upper selected values
      if (!variantInStock) {
        const optionsToCheck = [
          ...upperSelectedOptions,
          { option: option?.name, value: valueName, localizedName },
        ]

        const optionVariants = variants?.filter((variant) =>
          optionsToCheck.every((option) =>
            variant.options.some(
              (o) => o.option === option?.option && o.value === option?.value
            )
          )
        )

        variantInStock = optionVariants?.find((variant) =>
          checkVariantStock(product.id, variant.id)
        )
      }
      variantInStock && handleUpperOptionChange(variantInStock)
    } else {
      handleChange(option?.name, valueName, localizedName)
    }
  }

  return (
    <>
      <b>Select {option?.name}:</b>
      <ul
        className="grid justify-center grid-cols-3 gap-2 my-3 md:justify-start"
        style={{ listStyle: "none" }}
      >
        {option?.values.map((o, i) => {
          let isSelected = false
          if (
            selectedCartOption &&
            selectedCartOption.valueId &&
            selectedCartOption.valueName
          ) {
            isSelected =
              o.id === selectedCartOption.valueId &&
              o.name === selectedCartOption.valueName &&
              o.localizedName === selectedCartOption.localizedName
          }

          const hasStock = hasAnyStockAvailable(o.name, o.localizedName)

          if (o.active != null && o.active == false) {
            return null
          }

          return isColor ? (
            <ColorPickerSingleClient
              key={`option-${uid}-${index}-${i}`}
              option={o}
              state={
                !hasStock
                  ? OptionsSelectorSingleItemState.disabled
                  : isSelected
                    ? OptionsSelectorSingleItemState.selected
                    : OptionsSelectorSingleItemState.default
              }
              handleClick={handleClick}
            />
          ) : (
            <GenericOptionsPickerSingleClient
              key={`option-${uid}-${index}-${i}`}
              option={o}
              state={
                !hasStock
                  ? OptionsSelectorSingleItemState.disabled
                  : isSelected
                    ? OptionsSelectorSingleItemState.selected
                    : OptionsSelectorSingleItemState.default
              }
              handleClick={handleClick}
            />
          )
        })}
      </ul>
      {isSize && (product as Payload.Product)?.sizeCharts && (
        <div className="block mb-4">
          <SizeChartsClient
            data={
              (product as Payload.Product)
                ?.sizeCharts as Payload.ProductSizeChart[]
            }
            product={product.name}
          />
        </div>
      )}
    </>
  )
}
